import React from 'react';
import RoundedButton from 'components/button/rounded-button';
import { useQueryClient } from 'react-query';
import useCreatePool from 'contracts/poolManager/hooks/useCreatePool';
import PledgeStatus from 'contracts/pledgeVault/constants';

const PoolCreateButton = ({ dealId, onCreate }) => {
  const queryClient = useQueryClient();

  const { createPool, isLoading } = useCreatePool(dealId, {
    onSuccess: (address) => {
      queryClient.invalidateQueries(['admin-deal', dealId]);

      onCreate(address);
    },
  });

  return (
    <RoundedButton
      type="secondary"
      disabled={isLoading}
      onClick={() => createPool(PledgeStatus.OpenUnlocked)}
    >
      Open
    </RoundedButton>
  );
};

export default PoolCreateButton;
