import React, { useEffect, useState } from 'react';
import NumberInput from 'components/input/number-input';
import PoolStatus from 'contracts/poolManager/constants';

import DeployContractAction from '../actions/DeployContract';
import CreatePhasesAction from '../actions/CreatePhases';
import { useDeployContractContext } from '../context';
import { validatePoolConfigField } from './validators';

const PoolConfigParams = ['minContribution', 'maxContribution', 'dealSize'];

const DeployContractHeader = () => {
  const { deal, setPoolConfig, poolConfig, isLoading } = useDeployContractContext();

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const updatedConfig = PoolConfigParams.reduce((acc, key) => {
      if (deal[key]) {
        acc[key] = deal[key];
      }
      return acc;
    }, {});

    setPoolConfig((prevDetails) => ({
      ...prevDetails,
      ...updatedConfig,
    }));
  }, [deal, setPoolConfig]);

  const handleChange = (key, value) => {
    validatePoolConfigField(key, value, {
      setErrors,
      poolConfig,
    });

    setPoolConfig((prev) => ({ ...prev, [key]: value }));
  };

  const onValidate = () => {
    const errors = PoolConfigParams.map((key) =>
      validatePoolConfigField(key, poolConfig[key], {
        setErrors,
        poolConfig,
      })
    );

    return { errors, isFormValid: !errors.some((error) => !!error) };
  };

  const hasErrors = Object.values(errors).some((error) => !!error);

  return (
    <div className="general">
      <div className="general__inputs">
        <NumberInput
          label="Min Contribution"
          value={poolConfig.minContribution}
          onChange={(e) => handleChange('minContribution', e.target.value)}
          decimalNumber="2"
          error={errors.minContribution}
          disabled={isLoading}
        />
        <NumberInput
          label="Max Contribution"
          subLabel="(optional)"
          value={poolConfig.maxContribution}
          onChange={(e) => handleChange('maxContribution', e.target.value)}
          decimalNumber="2"
          error={errors.maxContribution}
          disabled={isLoading}
        />
        <NumberInput
          label="Deal Size"
          value={poolConfig.dealSize}
          onChange={(e) => handleChange('dealSize', e.target.value)}
          decimalNumber="2"
          error={errors.dealSize}
          disabled={isLoading}
        />
      </div>
      <div className="general__actions">
        <div className="general__actions-deploy">
          {deal.statusId === PoolStatus.Active ? (
            <CreatePhasesAction disabled={hasErrors || isLoading} onValidate={onValidate} />
          ) : (
            <DeployContractAction disabled={hasErrors || isLoading} onValidate={onValidate} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DeployContractHeader;
