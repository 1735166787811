import { ethers } from 'ethers';
import { getDealStatusName } from 'models/dealStatusModel';
import { formatClaimAmount } from 'models/claimerModel';
import { XDAI_CHAIN_ID } from '../constants';
import PoolArtifact from './poolManager/abi/IPoolManager.json';
import { getProviderByChainId } from './providers';
import { getTokenInfo } from './erc20';

export const getMyContributionAmount = async (provider, dealAddress, walletAddress) => {
  const poolContract = new ethers.Contract(dealAddress, PoolArtifact.abi, provider);

  try {
    const contributionAmountBN = await poolContract.userContributions(walletAddress);

    return ethers.utils.formatUnits(contributionAmountBN, 'mwei');
  } catch (error) {
    console.error(
      `error ${error.message} during getAddressContributionAmount call for ${dealAddress} ${walletAddress}`
    );
    return '0.0';
  }
};

export const getDealInfo = async (dealAddress, provider) => {
  if (!provider) throw new Error('Provider not passed');

  const poolContract = new ethers.Contract(dealAddress, PoolArtifact.abi, provider);

  const dealInfo = await poolContract.getPoolInfo();

  return {
    dealSize: ethers.utils.formatUnits(dealInfo.dealSize, 'mwei'),
    minContribution: ethers.utils.formatUnits(dealInfo.minContribution, 'mwei'),
    maxContribution: ethers.utils.formatUnits(dealInfo.maxContribution, 'mwei'),
    raisedAmount: ethers.utils.formatUnits(dealInfo.totalContribution, 'mwei'),
    status: getDealStatusName(dealInfo.status),
  };
};

export const getAvailableClaimAmountFromClaimer = async (
  provider,
  claimerAddress,
  userWalletAddress
) => {
  const poolContract = new ethers.Contract(claimerAddress, PoolArtifact.abi, provider);

  const availableClaimAmount = await poolContract.getAvailableWithdrawAmount(userWalletAddress);

  return availableClaimAmount;
};

const getClaimerClaimAmountsForToken = async (
  provider,
  claimerAddress,
  tokenAddress,
  walletAddress
) => {
  try {
    const claimAmount = await getAvailableClaimAmountFromClaimer(
      provider,
      claimerAddress,
      walletAddress
    );

    const tokenInfo = await getTokenInfo(provider, tokenAddress);

    return {
      claimAmount: formatClaimAmount(claimAmount, tokenInfo),
      token: {
        address: tokenAddress,
        ...tokenInfo,
      },
    };
  } catch (error) {
    return { claimAmount: '0.0', token: null };
  }
};

export const getClaimerData = async (claimers, walletAddress) => {
  return Promise.all(
    claimers.map(async (claimer) => {
      delete claimer.claimableAmountCalls;

      if (!claimer.tokens.length && claimer.chainId !== +XDAI_CHAIN_ID) {
        return { ...claimer, claimableAmounts: [], token: null };
      }

      const provider = getProviderByChainId(claimer.chainId);

      const claimableAmountsPromises = claimer.tokens.map(({ address }) =>
        getClaimerClaimAmountsForToken(provider, claimer.address, address, walletAddress)
      );

      return {
        ...claimer,
        claimableAmounts: await Promise.all(claimableAmountsPromises),
      };
    })
  );
};
