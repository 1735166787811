import React, { useState } from 'react';
import LogTab from 'features/deals/LogTab';
import RoundedButton from 'components/button/rounded-button';
import { useSelector } from 'react-redux';
import { manuallyGenerateUngeneratedInvoices } from 'services/apiService';
import useAdminDealQuery from 'hooks/useAdminDealQuery';
import DealInfoTab from './DealInfoTab';
import ContributionsTab from './ContributionsTab';
import PledgeTab from './PledgeTab';
import DeployContractTab from './DeployContractTab';
import InvoicesTab from './InvoiceTab';
import VestingTab from '../DealInfoUser/DealInfoDesktop/VestingTab';
import AdminTab from '../DealInfoUser/DealInfoDesktop/AdminTab';

const DealInfoDesktop = ({
  showDropdown,
  activeInfoTab,
  setActiveInfoTab,
  overviewModalOpen,
  toggleOverviewModal,
  highlightsModalOpen,
  toggleHighlightsModal,
  basicMetricsModalOpen,
  toggleBasicMetricsModal,
  blackDragonDealModalOpen,
  toggleBlackDragonDealModal,
  raiseStatsModalOpen,
  toggleRaiseStatsModal,
  vestingClaimInfoModalOpen,
  toggleVestingClaimInfoModal,
  partnersModalOpen,
  togglePartnersModal,
  teamModalOpen,
  adminOverviewModalOpen,
  toggleAdminOverviewModal,
  toggleTeamModal,
  projectsModalOpen,
  toggleProjectsModal,
  vestingBatchInfoModalOpen,
  toggleVestingBatchInfoModal,
  deal: initDeal,
  pledges,
  onRefetch,
}) => {
  const authReducer = useSelector((state) => state.auth);
  const { isAdmin } = authReducer;

  const [isInvoiceGenerated, setIsInvoiceGenerated] = useState(false);

  const handleManualInvoicesGeneration = async () => {
    await manuallyGenerateUngeneratedInvoices(initDeal.id);
    setIsInvoiceGenerated(true);
  };

  const { deal } = useAdminDealQuery(initDeal);

  return (
    <div className={`deal-info-desktop${showDropdown ? ' show' : ''}`}>
      <div className="deal-info-tabs">
        <div
          className={`deal-info-tab${activeInfoTab === 'info' ? ' active' : ''}`}
          onClick={() => setActiveInfoTab('info')}
        >
          DEAL INFO
        </div>
        <div
          className={`deal-info-tab${activeInfoTab === 'pledge' ? ' active' : ''}`}
          onClick={() => setActiveInfoTab('pledge')}
        >
          PLEDGE
        </div>
        <div
          className={`deal-info-tab${activeInfoTab === 'log' ? ' active' : ''}`}
          onClick={() => setActiveInfoTab('log')}
        >
          CHANGE LOG
        </div>
        <div
          className={`deal-info-tab${activeInfoTab === 'deploy' ? ' active' : ''}`}
          onClick={() => setActiveInfoTab('deploy')}
        >
          DEPLOY CONTRACT
        </div>
        <div
          className={`deal-info-tab${activeInfoTab === 'contributions' ? ' active' : ''}`}
          onClick={() => setActiveInfoTab('contributions')}
        >
          CONTRIBUTIONS
        </div>
        {deal.statusId === 3 && (
          <div
            className={`deal-info-tab${activeInfoTab === 'invoices' ? ' active' : ''}`}
            onClick={() => setActiveInfoTab('invoices')}
          >
            INVOICES
          </div>
        )}
        <div
          className={`deal-info-tab${activeInfoTab === 'vesting' ? ' active' : ''}`}
          onClick={() => setActiveInfoTab('vesting')}
        >
          VESTING
        </div>
        {isAdmin && (
          <div
            className={`deal-info-tab${activeInfoTab === 'admin' ? ' active' : ''}`}
            onClick={() => setActiveInfoTab('admin')}
          >
            ADMIN
          </div>
        )}
      </div>
      <div className="deal-info-content">
        {activeInfoTab === 'info' && (
          <DealInfoTab
            overviewModalOpen={overviewModalOpen}
            toggleOverviewModal={toggleOverviewModal}
            highlightsModalOpen={highlightsModalOpen}
            toggleHighlightsModal={toggleHighlightsModal}
            basicMetricsModalOpen={basicMetricsModalOpen}
            toggleBasicMetricsModal={toggleBasicMetricsModal}
            blackDragonDealModalOpen={blackDragonDealModalOpen}
            toggleBlackDragonDealModal={toggleBlackDragonDealModal}
            raiseStatsModalOpen={raiseStatsModalOpen}
            toggleRaiseStatsModal={toggleRaiseStatsModal}
            partnersModalOpen={partnersModalOpen}
            togglePartnersModal={togglePartnersModal}
            teamModalOpen={teamModalOpen}
            toggleTeamModal={toggleTeamModal}
            projectsModalOpen={projectsModalOpen}
            toggleProjectsModal={toggleProjectsModal}
            deal={deal}
            open={showDropdown}
          />
        )}
        {activeInfoTab === 'pledge' && (
          <PledgeTab deal={deal} pledges={pledges} onRefetch={onRefetch} />
        )}
        {activeInfoTab === 'log' && <LogTab deal={deal} />}
        {activeInfoTab === 'deploy' && deal && <DeployContractTab deal={deal} />}
        {activeInfoTab === 'contributions' && <ContributionsTab deal={deal} />}
        {activeInfoTab === 'invoices' &&
          (deal.hasInvoice || isInvoiceGenerated ? (
            <InvoicesTab deal={deal} />
          ) : (
            <RoundedButton type="primary" onClick={handleManualInvoicesGeneration}>
              Generate Invoices
            </RoundedButton>
          ))}
        {activeInfoTab === 'vesting' && (
          <VestingTab
            deal={deal}
            vestingClaimInfoModalOpen={vestingClaimInfoModalOpen}
            toggleVestingClaimInfoModal={toggleVestingClaimInfoModal}
            vestingBatchInfoModalOpen={vestingBatchInfoModalOpen}
            toggleVestingBatchInfoModal={toggleVestingBatchInfoModal}
          />
        )}
        {activeInfoTab === 'admin' && isAdmin && (
          <AdminTab
            deal={deal}
            adminOverviewModalOpen={adminOverviewModalOpen}
            toggleAdminOverviewModal={toggleAdminOverviewModal}
          />
        )}
      </div>
    </div>
  );
};

export default DealInfoDesktop;
