import React from 'react';
import OldUserPledgeControl from './OldUserPledgeControl';
import NewUserPledgeControl from './NewUserPledgeControl';

function UserPledgeControl({ deal, ...props }) {
  if (deal.useOldPledge) {
    return <OldUserPledgeControl deal={deal} {...props} />;
  }

  return <NewUserPledgeControl deal={deal} {...props} />;
}

export default UserPledgeControl;
