import React from 'react';
import NumberInput from 'components/input/number-input';
import RoundedButton from 'components/button/rounded-button';
import { usePledgeContext } from '../context';

const UpdatePledgeControls = () => {
  const {
    state,
    isLoading,
    onUpdateTotalRelease,
    initState,
    onToggleManualInput,
    onToggleDistributeEvenly,
    onApplyAll,
  } = usePledgeContext();

  const { totalRelease, isManualInput, error, isDistributeEvenly } = state;

  return (
    <div className="claim-deal-check-modal__head">
      {isManualInput ? (
        <div className="claim-deal-check-modal__header">
          <div className="claim-deal-check-modal__header-row">
            <div className="switch-wrapper">
              <NumberInput
                label="Distribute Amount:"
                value={totalRelease.distribute}
                onChange={onUpdateTotalRelease}
                decimalNumber="2"
              />
              <RoundedButton
                type="secondary"
                onClick={onToggleDistributeEvenly}
                disabled={isDistributeEvenly}
              >
                Distribute
              </RoundedButton>
            </div>

            {error && <div className="claim-deal-check-modal__error">{error}</div>}
          </div>
        </div>
      ) : null}
      <div className="claim-deal-check-modal__buttons">
        {!isManualInput ? (
          <RoundedButton onClick={onToggleManualInput} disabled={isManualInput}>
            Edit mode
          </RoundedButton>
        ) : (
          <>
            <RoundedButton disabled={isLoading} onClick={onApplyAll}>
              Preview
            </RoundedButton>
            <RoundedButton type="primary" disabled={isLoading} onClick={initState}>
              Reset
            </RoundedButton>
          </>
        )}
      </div>
    </div>
  );
};

export default UpdatePledgeControls;
