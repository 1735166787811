import { useNotifications } from 'hooks';

import { useQueryClient } from 'react-query';

import useRevokeRole from 'contracts/poolManager/hooks/roles/useRevokeRole';
import useApiRevokeRole from './api/useApiRevokeRole';
import { EMPTY_MUTATION_ARGS } from '../constants';

const useRoleRevoke = (userAddress, { onSuccess, onError } = EMPTY_MUTATION_ARGS) => {
  const [showSuccessNotification] = useNotifications();

  const queryClient = useQueryClient();

  const { revokeRole: apiRevokeRole, isLoading: isApiRevokingRole } = useApiRevokeRole(
    userAddress,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users-roles']);

        showSuccessNotification('Role revoked successfully');
        onSuccess();
      },
      onError,
    }
  );

  const { executeMethod: contactRevokeRole, isLoading: isContractRoleRevoking } = useRevokeRole(
    userAddress,
    {
      onSuccess: apiRevokeRole,
      onError,
    }
  );

  return {
    revokeRole: contactRevokeRole,
    isLoading: isApiRevokingRole || isContractRoleRevoking,
  };
};

export default useRoleRevoke;
