import { useQuery } from 'react-query';
import { getDealInfo } from 'services/apiService';
import { formatOverviewText } from 'utils';

export default function useDealInfo(initDeal, { enabled } = {}) {
  const { data: deal, isFetching, refetch } = useQuery(
    ['deal', initDeal.id],
    async () => {
      const result = await getDealInfo(initDeal.id);

      return {
        ...initDeal,
        ...result,
        overview: formatOverviewText(result.overview),
        highlights: formatOverviewText(result.highlights),
      };
    },
    {
      enabled,
    }
  );

  return {
    deal,
    isLoading: isFetching,
    refetch,
  };
}
