import React from 'react';
import RoundedButton from 'components/button/rounded-button';
import { usePledgeContext } from '../context';

function UpdatePledgesFooter({ onClose }) {
  const { batchReleasePledge, pledges, state, isLoading, initState } = usePledgeContext();

  const { totalRelease, error } = state;

  return (
    <div className="claim-deal-check-modal__footer">
      <RoundedButton
        type="primary"
        disabled={isLoading}
        onClick={() => {
          initState();
          onClose();
        }}
      >
        Cancel
      </RoundedButton>
      <RoundedButton
        type="secondary"
        disabled={error || !totalRelease.distribute || totalRelease.isDirty || isLoading}
        onClick={() => {
          batchReleasePledge(pledges);
        }}
      >
        Update
      </RoundedButton>
    </div>
  );
}

export default UpdatePledgesFooter;
