import { Divider, Grid } from '@material-ui/core';
import IconButton from 'components/button/icon-button';
import React from 'react';
import RoundedButton from 'components/button/rounded-button';

const RoleList = ({ roles, onCreateNew, onEdit, onDelete }) => {
  return (
    <Grid item xs={4} classes={{ root: 'role-list' }}>
      <div className="role-list__title">
        <h3>Role list</h3>
        <RoundedButton type="primary" onClick={onCreateNew}>
          Create new
        </RoundedButton>
      </div>
      <Divider />
      <div className="role-list__items">
        {roles.map((role) => (
          <div key={role.id} className="role-list__item">
            <label>{role.name}</label>
            <div className="role-list__icons">
              <IconButton icon="iconDealsEdit" onClick={() => onEdit(role)} />
              <IconButton icon="iconDealsErase" onClick={() => onDelete(role)} />
            </div>
          </div>
        ))}
      </div>
    </Grid>
  );
};

export default RoleList;
