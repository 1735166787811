import React from 'react';
import './index.scss';
import SvgIcon from 'components/svgIcon';

export default function BlurContainer({ children, isValid, message, className }) {
  return (
    <div className={`validation-container ${className || ''}`}>
      {children}

      {!isValid && (
        <div className="validation-overlay">
          <div className="validation-message">
            <SvgIcon name="error" />
            <p>{message}</p>
          </div>
        </div>
      )}
    </div>
  );
}
