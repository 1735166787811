import React from 'react';

function withNewPledge(Component) {
  const WrappedComponent = ({ deal, ...props }) => {
    if (deal.useOldPledge) {
      return null;
    }

    return <Component deal={deal} {...props} />;
  };

  WrappedComponent.displayName = `withNewPledge(${
    Component.displayName || Component.name || 'withNewPledge'
  })`;

  return WrappedComponent;
}

export default withNewPledge;
