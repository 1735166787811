import { getAllAdminDeals } from 'services/apiService';
import { useQuery, useQueryClient } from 'react-query';
import { getAdminDealModels } from 'models/admin';

const useAdminDealsQuery = (authState, { onSuccess, onError, enabled }) => {
  const queryClient = useQueryClient();

  const { data, isFetching } = useQuery(
    ['admin-deals'],
    async () => {
      const adminDealsApi = await getAllAdminDeals();

      const deals = await getAdminDealModels(authState, adminDealsApi);

      deals.forEach((deal) => {
        queryClient.setQueryData(['admin-deal', deal.id], deal);
      });

      return deals;
    },
    {
      onSuccess,
      onError,
      enabled,
    }
  );

  return {
    deals: data,
    isLoading: isFetching,
  };
};

export default useAdminDealsQuery;
