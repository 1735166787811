import { useQueryClient } from 'react-query';
import { POOL_MANAGER_ADDRESS } from 'constants/config';

import useContractMethod from 'contracts/common/useContractMethod';
import usePoolManager from '../usePoolManager';

const useRevokeRole = (userAddress, { onSuccess, onError }) => {
  const queryClient = useQueryClient();

  const poolManager = usePoolManager(POOL_MANAGER_ADDRESS);

  return useContractMethod(() => poolManager.removeRoleFromAccount(userAddress), {
    onSuccess: () => {
      queryClient.invalidateQueries(['roles']);
      onSuccess();
    },
    onError,
    listenerOptions: {
      eventName: 'RoleRemovedFromAccount',
      errorMessage: 'Role not revoked',
    },
  });
};

export default useRevokeRole;
