const PoolConfigValidationFields = {
  minContribution: 'minContribution',
  maxContribution: 'maxContribution',
  dealSize: 'dealSize',
};

const errorMessages = {
  minContribution: {
    greaterThanMax: 'Min contribution must be less than max contribution',
  },
  maxContribution: {
    lessThanMin: 'Max contribution must be greater than min contribution',
  },
  dealSize: {
    lessThanContribution: 'Deal size must be greater than total contribution',
  },
};

const validatePoolConfigField = (field, value, args) => {
  const { poolConfig, setErrors } = args;

  let error = null;

  switch (field) {
    case PoolConfigValidationFields.minContribution:
      if (!!poolConfig.maxContribution && +value > +poolConfig.maxContribution) {
        error = errorMessages.minContribution.greaterThanMax;
      }
      break;
    case PoolConfigValidationFields.maxContribution:
      if (!!value && +value < +poolConfig.minContribution) {
        error = errorMessages.maxContribution.lessThanMin;
      }
      break;
    case PoolConfigValidationFields.dealSize:
      if (+value < +poolConfig.totalContribution) {
        error = errorMessages.dealSize.lessThanContribution;
      }
      break;
    default:
      break;
  }

  setErrors((prev) => ({
    ...prev,
    [field]: error,
  }));

  return error;
};

export { validatePoolConfigField };
