import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import UserRoleTableRow from './UserRoleTableRow';

const UserRoleTable = ({ users, onEdit }) => {
  if (!users) return null;

  return (
    <TableContainer classes={{ root: 'users-table' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>NAME</TableCell>
            <TableCell>EMAIL</TableCell>
            <TableCell>ADDRESS</TableCell>
            <TableCell>USER TYPE</TableCell>
            <TableCell>JOINED</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <UserRoleTableRow key={user.id} user={user} onEdit={onEdit} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserRoleTable;
