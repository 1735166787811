import React, { useState } from 'react';

import './index.scss';
import CustomSelect from 'components/select/custom-select';
import InfoTooltip from 'components/tooltip/custom/InfoTooltip';
import usePledgeVersionUpdate from './hooks/usePledgeVersionUpdate';

function PledgeVersionSwitch({ dealId, initialValue }) {
  const [useOldPledge, setUseOldPledge] = useState(initialValue || false);

  const { mutate: updatePledgeVersion } = usePledgeVersionUpdate(dealId);

  const handleChange = async (value) => {
    if (value === initialValue) return;

    setUseOldPledge(value);

    await updatePledgeVersion({ useOldPledge: value });
  };

  const VersionList = [
    { value: true, name: 'Off-chain pledges' },
    { value: false, name: 'Auto-transfer pledges' },
  ];

  return (
    <div className="pledge-version-switch">
      <div className="pledge-version-switch-header">
        <p className="label">Pledge version</p>
        <InfoTooltip
          title="You can switch between V1 and V2 pledge versions when deal is draft"
          placement="right-start"
        />
      </div>
      <CustomSelect
        value={useOldPledge}
        items={VersionList}
        onSelect={(_, value) => handleChange(value)}
      />
    </div>
  );
}

export default PledgeVersionSwitch;
