import React from 'react';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import CustomProgressBar from 'components/progress-bar/custom-progress-bar';
import { usePledgeContext } from '../context';

const UpdatePledgeHeader = ({ deal }) => {
  const { state, originalPledgedAmount } = usePledgeContext();

  return (
    <div className="update-pledges-header">
      <div className="update-pledges-header">
        <div className="update-pledges-top-header">
          <div className="update-pledges-header-title">
            <h3>Update Pledges</h3>
            <span className="update-pledges-header-description">
              Adjust pledge amounts for each wallet
            </span>
          </div>
          <div
            className="update-pledges-header-total"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              alignItems: 'flex-end',
            }}
          >
            <div>Original Pledge Amount: {originalPledgedAmount} USDT</div>
            <div>Total Pledge Release: {state.totalRelease.amountFormatted} USDT</div>
            <div>Refund Amount: {state.totalRelease.refund} USDT</div>
          </div>
        </div>
      </div>
      <div>
        <RoundedAvatar src={deal.imageUrl} />
        <div>
          <span>{deal.name}</span>
          {deal.dealSize && deal.raisedAmount && (
            <CustomProgressBar total={Number(deal.dealSize)} value={Number(deal.raisedAmount)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdatePledgeHeader;
