import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const ContractUsersTableRow = ({ data }) => {
  return (
    <TableRow>
      <TableCell>{data.user.username}</TableCell>
      <TableCell>{data.user.wallet}</TableCell>
      <TableCell>{data.roleName || 'N/A'}</TableCell>
      <TableCell>
        <NumberFormat
          value={Number(data.maxCloseAmount / 1000000).toFixed(0)}
          thousandSeparator
          displayType="text"
          prefix="$"
        />
      </TableCell>
      <TableCell>{moment(data.createdAt).format('DD-MMM-YYYY')}</TableCell>
    </TableRow>
  );
};

export default ContractUsersTableRow;
