import { useNotifications } from 'hooks';
import { useMutation, useQueryClient } from 'react-query';
import { updateDealPledgeVersion } from 'services/apiService';

function usePledgeVersionUpdate(dealId) {
  const queryClient = useQueryClient();

  const [showSuccessNotification, showErrorNotification] = useNotifications();

  return useMutation({
    mutationFn: (data) => updateDealPledgeVersion(dealId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin-deal', dealId] });

      showSuccessNotification('Pledge version updated successfully');
    },
    onError: () => {
      showErrorNotification('Failed to update pledge version');
    },
  });
}

export default usePledgeVersionUpdate;
