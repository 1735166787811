import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { updateLoading, addNotification, setActiveHashes } from 'store/actions';

import { ethers } from 'ethers';
import useErc20Approve from 'contracts/erc20/useERC20Approve';
import { DEFAULT_CHAIN_NAME, PLEDGE_VAULT_ADDRESS, USDT_ADDRESS } from 'constants/config';
import notificationTypes from 'constants/notificationTypes';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import { MutationArgs } from 'utils/types';
import usePledgeVault from './usePledgeVault';

const usePledgeMutation = (address, { onSuccess, onError } = MutationArgs) => {
  const dispatch = useDispatch();

  const pledgeContract = usePledgeVault();

  const { approve } = useErc20Approve(USDT_ADDRESS, { onError });

  const queryClient = useQueryClient();

  const { mutateAsync: pledge, isLoading, isError, error } = useMutation(
    async (amountInEther) => {
      const amountInWei = ethers.utils.parseUnits(amountInEther, 'mwei');

      await approve({ spender: PLEDGE_VAULT_ADDRESS, amountInWei });

      const tx = await pledgeContract.pledge(address, amountInWei);

      const receipt = await tx.wait();

      return {
        receipt,
      };
    },
    {
      onMutate: () => {
        dispatch(updateLoading(true));
      },
      onSuccess: ({ receipt }) => {
        dispatch(updateLoading(false));
        dispatch(
          setActiveHashes([
            {
              hash: receipt.transactionHash,
              chain: DEFAULT_CHAIN_NAME,
              pending: false,
              callback: () => {
                queryClient.invalidateQueries(['pool', address]);
                queryClient.invalidateQueries(['pool-info', address]);
                queryClient.invalidateQueries(['pledge-balance']);
              },
            },
          ])
        );
        dispatch(
          addNotification({
            name: receipt.transactionHash,
            chain: DEFAULT_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );

        onSuccess();
      },
      onError: () => {
        dispatch(updateLoading(false));
        dispatch(
          addNotification({
            name: 'Pledge Error',
            status: 'error',
            statusText: 'Error!',
            time: Date.now(),
            chain: DEFAULT_CHAIN_NAME,
            type: notificationTypes.GENERAL,
          })
        );
        onError();
      },
    }
  );

  const checkedPledge = useWithCorrectNetwork(pledge);

  return {
    pledge: checkedPledge,
    isLoading,
    isError,
    error,
  };
};

export default usePledgeMutation;
