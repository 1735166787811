import PledgeStatus from 'contracts/pledgeVault/constants';
import PoolStatus from 'contracts/poolManager/constants';
import { DealValidState } from './constants';

const isDealValid = (deal, type) => {
  console.log('deal.statusId:', deal.statusId);
  switch (type) {
    case DealValidState.PledgeFreezed: {
      // deal is promoted to live
      if (deal.datePublished) {
        return true;
      }

      if (!deal.isValidPledgeStatus) {
        return false;
      }

      if (!deal.useOldPledge) {
        return deal?.status === PledgeStatus.Freezed;
      }

      return true;
    }
    case DealValidState.FeeExists:
      return !!deal.fee;
    case DealValidState.CorrectState:
      return [
        PoolStatus.Active,
        PoolStatus.Paused,
        PoolStatus.NotActive,
        PoolStatus.Freezed,
      ].includes(deal.statusId);
    default:
      return false;
  }
};

export { isDealValid };
