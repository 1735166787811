import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { formatAddress } from 'utils';

const ContractRoleTableRow = ({ data }) => {
  return (
    <TableRow>
      <TableCell>{data.roleName}</TableCell>
      <TableCell>
        <NumberFormat
          value={Number(data.maxCloseAmount / 1000000).toFixed(0)}
          thousandSeparator
          displayType="text"
          prefix="$"
        />
      </TableCell>
      <TableCell>{moment(data.createdAt).format('DD-MMM-YYYY')}</TableCell>
      <TableCell>{data.dealFactoryAddress}</TableCell>
      <TableCell>
        <a
          href={`https://gnosisscan.io/tx/${data.txHash}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {formatAddress(data.txHash)}
        </a>
      </TableCell>
    </TableRow>
  );
};

export default ContractRoleTableRow;
