import { addChain } from 'contracts/browserWallet';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { decToHex } from 'utils';

export default function useChainSwitcher(currentNetworkId, wantedNetworkId) {
  const provider = useSelector((state) => state.global.provider);

  const switchChain = useCallback(
    async (chainId) => {
      await provider.send('wallet_switchEthereumChain', [{ chainId }]);
    },
    [provider]
  );

  return useCallback(
    async (onSuccessfulNetworkChange) => {
      const hexChainID = decToHex(+wantedNetworkId);

      try {
        await switchChain(hexChainID);

        onSuccessfulNetworkChange();
      } catch (switchError) {
        console.log('switchError:', switchError);
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code !== 4902) {
          return;
        }

        await addChain(currentNetworkId, wantedNetworkId);
        onSuccessfulNetworkChange();
      }
    },
    [currentNetworkId, switchChain, wantedNetworkId]
  );
}
