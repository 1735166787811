import { ethers } from 'ethers';
import { getDealStatusName } from 'models/dealStatusModel';

function decodeDealInfo(dealInfo) {
  const [dealSize, minContribution, maxContribution, totalContribution, status] = dealInfo[0];

  // TODO: check if we need to convert to ether
  return {
    dealSize: ethers.utils.formatUnits(dealSize, 'mwei'),
    minContribution: ethers.utils.formatUnits(minContribution, 'mwei'),
    maxContribution: ethers.utils.formatUnits(maxContribution, 'mwei'),
    raisedAmount: ethers.utils.formatUnits(totalContribution, 'mwei'),
    status: getDealStatusName(status),
  };
}

const decodeTokenInfo = (results, offset = 0) => {
  const name = results[offset]?.returnData?.[0] || 'Unknown';
  const symbol = results[offset + 1]?.returnData?.[0] || 'Unknown';
  const decimals = results[offset + 2]?.returnData?.[0] || 18;

  return { name, symbol, decimals };
};

export { decodeDealInfo, decodeTokenInfo };
