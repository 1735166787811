import React, { useState, useEffect } from 'react';
import RoundedButton from 'components/button/rounded-button';
import NumberInput from 'components/input/number-input';
import './index.scss';
import { roundNumberToDecimals } from 'utils/helpers';
import { Dialog } from '@material-ui/core';
import { USDT_ADDRESS } from 'constants/config';
import useERC20Balance from 'contracts/erc20/useERC20Balance';

const PledgeModal = ({ pledgeMutation, isOpen, onCancel, defaultValue }) => {
  const [data, setData] = useState({
    amount: defaultValue ? roundNumberToDecimals(Number(defaultValue), 0) : '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const { pledge, isLoading, isError, error } = pledgeMutation || {};

  const { balance } = useERC20Balance(USDT_ADDRESS);

  const getErrorMessage = () => {
    if (errorMessage === 'min') return 'Min. = 250';

    if (errorMessage === 'max') return `Max. = ${balance}`;
    return '';
  };

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    if (Number(data.amount) < 1 && Number(data.amount) !== 0) {
      setErrorMessage('min');
    } else if (Number(data.amount) >= balance) {
      setErrorMessage('max');
    } else {
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onConfirm = async () => {
    await pledge(data.amount);
  };

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <div className="bd-modal">
        <div className="bd-modal-inner">
          <div className="bd-modal-header">
            <div className="input-holder">
              <NumberInput
                label="Pledge amount:"
                name="amount"
                value={data.amount.toString()}
                onChange={onChangeData}
                error={getErrorMessage()}
                decimalNumber="0"
              />
            </div>
            <RoundedButton onClick={onCancel}>Cancel</RoundedButton>
            <RoundedButton type="secondary" onClick={onConfirm} disabled={isLoading}>
              <div className="d-flex">Confirm</div>
            </RoundedButton>
            {isError && <div className="error-message">{error.message}</div>}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PledgeModal;
