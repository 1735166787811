import { ClickAwayListener, Popper } from '@material-ui/core';
import IconButton from 'components/button/icon-button';
import SvgIcon from 'components/svgIcon';

import React, { useRef, useState } from 'react';
import useRoleRevoke from '../hooks/useRoleRevoke';

const UserManageDropdown = ({ wallet, onEdit }) => {
  const buttonRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const { revokeRole } = useRoleRevoke(wallet);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className="deal-manage">
        <div ref={buttonRef} className="deal-manage__button">
          <IconButton icon="iconMore" onClick={() => setIsOpen((prev) => !prev)} />
        </div>
        <Popper open={isOpen} anchorEl={buttonRef.current} placement="bottom-end">
          <div className="deal-manage__dropdown" onClick={(e) => e.stopPropagation()}>
            <div onClick={onEdit}>
              <SvgIcon name="iconPollEdit" />
              <span>Edit</span>
            </div>
            <div onClick={revokeRole}>
              <SvgIcon name="iconErase" />
              <span>Delete</span>
            </div>
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default UserManageDropdown;
