import { useMutation } from 'react-query';

import { addRoleToUser as apiAssignRole } from 'services/apiService';

const useApiAssignRole = (assignData, { onSuccess, onError }) => {
  const { mutateAsync: assignRole, isLoading } = useMutation(() => apiAssignRole(assignData), {
    onSuccess,
    onError,
  });

  return {
    assignRole,
    isLoading,
  };
};

export default useApiAssignRole;
