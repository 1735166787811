import { ethers } from 'ethers';
import { XDAI_CHAIN_ID } from 'constants/config';
import buildCall from 'contracts/calls';
import { decodeTokenInfo } from 'models/common/decoders';
import { CALL_TYPE } from 'contracts/calls/constants';
import { formatClaimAmount, getClaimerData } from 'models/claimerModel';
import { groupByChain, groupDealsByStatus, isChainMulticallSupported } from 'models/common/helpers';
import { groupBy } from 'utils';
import { queryCallsByChain } from './metadata';

export const handleOldDealClaimers = async (oldDeals, accountInfo) => {
  const { liveDeals: oldLiveDeals, otherDeals: oldOtherDeals } = groupDealsByStatus(oldDeals);

  const oldDealsWithClaimers = await Promise.all(
    oldLiveDeals.map((deal) => handleClaimableDeal({ deal, walletAddress: accountInfo.address }))
  );

  return [...oldDealsWithClaimers, ...oldOtherDeals];
};

const handleClaimableDeal = async ({ deal, walletAddress }) => {
  const claimers = prepareClaimers(deal, walletAddress);

  const multicallCalls = [];

  const claimerAmountCalls = claimers.flatMap(({ claimableAmountCalls }) =>
    claimableAmountCalls.map((call, index) => ({
      call,
      index: multicallCalls.length + index,
      chainId: call.chainId,
    }))
  );

  multicallCalls.push(...claimerAmountCalls);

  const claimersByChain = groupByChain(multicallCalls);

  const multicallResults = await queryCallsByChain(claimersByChain, multicallCalls);

  const mergedClaimers = await mergeClaimers({
    claimers,
    walletAddress,
    contributedAmount: deal.contributedAmount,
    multicallResults,
  });

  return {
    ...deal,
    claimers: mergedClaimers,
  };
};

export function prepareClaimers(deal, walletAddress) {
  const claimers = deal.claimers || [];

  return claimers.map((claimer) => {
    if (
      !isChainMulticallSupported(claimer.chainId) ||
      claimer.tokens.find((token) => !isChainMulticallSupported(token.chainId))
    ) {
      return { ...claimer, claimableAmountCalls: [], notMulticallSupported: true };
    }

    if (!claimer.tokens.length && claimer.chainId !== +XDAI_CHAIN_ID) {
      return { ...claimer, claimableAmountCalls: [], token: null };
    }

    // prepare contract calls for claim amounts and token info
    const claimableAmountCalls = claimer.tokens.flatMap(({ address, chainId }) =>
      prepareClaimerAmountCalls({
        dealContributionAmount: deal.contributedAmount,
        claimerAddress: claimer.address,
        tokenAddress: address,
        walletAddress,
        chainId,
      })
    );

    return {
      ...claimer,
      claimableAmountCalls,
    };
  });
}

export function prepareClaimerAmountCalls({
  dealContributionAmount,
  claimerAddress,
  tokenAddress,
  walletAddress,
  chainId,
}) {
  const claimAmountCall = buildCall(CALL_TYPE.CLAIM_AMOUNT_OLD)(claimerAddress, chainId, [
    walletAddress,
    ethers.utils.parseUnits(dealContributionAmount || '0', 6),
    tokenAddress,
  ]);

  const tokenCalls = buildCall(CALL_TYPE.TOKEN_INFO)(tokenAddress, chainId);

  return [claimAmountCall, ...tokenCalls];
}

export async function mergeClaimers({ claimers, multicallResults, walletAddress }) {
  const { prefetchedClaimers = [], claimersToFetch = [] } = groupBy(claimers, (claimer) =>
    claimer.notMulticallSupported ? 'claimersToFetch' : 'prefetchedClaimers'
  );

  const fetchedClaimersResult = await getClaimerData(claimersToFetch, walletAddress);

  const prefetchedClaimersResult = mergePrefetchedClaimers(prefetchedClaimers, multicallResults);

  return [...fetchedClaimersResult, ...prefetchedClaimersResult];
}

export function mergePrefetchedClaimers(claimers, multicallResults) {
  let resultOffset = 0;

  return claimers.map((claimer) => {
    delete claimer.claimableAmountCalls;

    const updatedTokens = claimer.tokens.map(({ address }) => {
      const claimAmountIdx = resultOffset;

      const rawClaimAmount = multicallResults[claimAmountIdx]?.returnData?.[0] || '0';
      const tokenInfo = decodeTokenInfo(multicallResults, claimAmountIdx + 1);

      resultOffset += 4; // skip by token calls count(value, name, symbol, decimals)

      return {
        claimAmount: formatClaimAmount(rawClaimAmount, tokenInfo),
        token: { address, ...tokenInfo },
      };
    });

    return {
      ...claimer,
      claimableAmounts: updatedTokens,
    };
  });
}
