import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { updateLoading, setActiveHashes } from 'store/actions';
import { DEFAULT_CHAIN_NAME } from 'constants/config';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import { MutationArgs } from 'utils/types';
import usePhaseMerkleRoot from 'features/deals/DealInfo/hooks/usePhaseMerkleRoot';
import { useDeployContractContext } from 'features/deals/DealInfo/DeployContractTab/context';
import {
  prepareConfigForDeploy,
  preparePhasesForDeploy,
} from 'features/deals/DealInfo/DeployContractTab/helpers';
import useWithErrorNotifier from 'hooks/useWithErrorNotifier';
import usePoolManager from './usePoolManager';

const useFinalizePledgeAndConfigPool = (address, { onSuccess, onError } = MutationArgs) => {
  const dispatch = useDispatch();

  const { deal, poolConfig, phases } = useDeployContractContext();

  const poolManager = usePoolManager();

  const queryClient = useQueryClient();

  const { mutateAsync: finalizePledgeAndConfigPool, isLoading } = useMutation(
    async ({ poolConfig, phaseIds, phaseConfigs }) => {
      if (!address) {
        throw new Error('Pool not initialized!');
      }

      const tx = await poolManager.finalizePledgeAndConfigurePool(
        address,
        poolConfig,
        phaseIds,
        phaseConfigs
      );

      const receipt = await tx.wait();

      return {
        receipt,
      };
    },
    {
      onMutate: () => {
        dispatch(updateLoading(true));
      },
      onSuccess: ({ receipt }) => {
        dispatch(updateLoading(false));
        dispatch(
          setActiveHashes([
            {
              chain: DEFAULT_CHAIN_NAME,
              hash: receipt.transactionHash,
              callback: () => {
                queryClient.invalidateQueries(['pool', address]);
                queryClient.invalidateQueries(['pool-info', address]);
                onSuccess();
              },
              pending: false,
            },
          ])
        );
      },
      onError: () => {
        dispatch(updateLoading(false));
        onError();
      },
    }
  );

  const onFinalizePledgeAndConfigPool = useWithErrorNotifier((result) => {
    const phases = preparePhasesForDeploy(result.phases);

    const config = prepareConfigForDeploy(poolConfig);

    if (!phases || !config) {
      return;
    }

    finalizePledgeAndConfigPool({
      poolConfig: config,
      ...phases,
    });
  });

  const { insertMerkleRoots } = usePhaseMerkleRoot(deal.id, {
    onSuccess: onFinalizePledgeAndConfigPool,
  });

  const checkedInsertMerkleRoots = useWithCorrectNetwork(() => insertMerkleRoots(phases));

  return {
    finalizePledgeAndConfigPool: checkedInsertMerkleRoots,
    isLoading,
  };
};

export default useFinalizePledgeAndConfigPool;
