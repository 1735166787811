import { useCallback } from 'react';
import useNotifications from './useNotifications';

export default function useWithErrorNotifier(fn) {
  const [, showErrorNotification] = useNotifications();

  return useCallback(
    async (...args) => {
      try {
        return fn(...args);
      } catch (error) {
        showErrorNotification(error.message);
      }
    },
    [fn, showErrorNotification]
  );
}
