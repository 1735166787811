import React from 'react';
import Icon from 'components/svgIcon';
import Countdown from 'components/countdown';
import './index.scss';

export function ActivePhasesModal({ deal }) {
  return (
    <>
      <div className="active-phases-modal">
        {deal.activePhases?.map((phase) => (
          <div key={phase.id} className="active-phases-modal-item">
            <div className="active-phases-modal-item-left">
              <div className="active-phase-name">{phase.name}</div>
              <div className="active-phase-model">
                <Icon name="iconDealInfoLockup" />
                {phase.model}
              </div>
            </div>
            <div className="active-phase-countdown">
              <Countdown timestamp={phase.endTimestamp} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
