import React, { useMemo } from 'react';
import BlurContainer from 'components/blur-container';
import { useDeployContractContext } from '../../context';
import { DealValidState, InvalidStateMessages } from './constants';
import { isDealValid } from './validation';

function DeployBlurContainer({ children }) {
  const { deal, pledgePool, isValidPledgeStatus, isLoadingPool } = useDeployContractContext();

  const { isValid, message } = useMemo(() => {
    if (isLoadingPool) return { isValid: true, message: '' };

    const fullDeal = { ...deal, isValidPledgeStatus, status: pledgePool?.status };

    const invalidState = Object.keys(DealValidState).find((type) => !isDealValid(fullDeal, type));

    return {
      isValid: !invalidState,
      message: InvalidStateMessages[invalidState],
    };
  }, [deal, isValidPledgeStatus, pledgePool, isLoadingPool]);

  return (
    <BlurContainer isValid={isValid} message={message}>
      {children}
    </BlurContainer>
  );
}

export default DeployBlurContainer;
