import React, { useState } from 'react';
import Whitelist from 'features/deals/AdminDealsTable/Whitelist';
import SvgIcon from 'components/svgIcon';
import BdtLevelCapInput from 'features/deals/AdminDealsTable/BdtLevelCapInput';
import NumberInput from 'components/input/number-input';
import { useDeployContractContext } from '../context';
import Checkbox from 'components/checkbox';

const PhaseDetails = ({ phase, index }) => {
  const { setWhitelistPickerData, handlePhaseChange } = useDeployContractContext();

  const [isWhitelistEnabled, setIsWhitelistEnabled] = useState(false);

  const shouldDisplayWhitelist = () => {
    return (
      phase.minAccessLevel.toString() === '4' ||
      (Array.isArray(phase.whitelist) && phase.whitelist.length > 0) ||
      phase.minViewLevel.toString() === '4' ||
      (phase.model === 'WalletCap' && isWhitelistEnabled)
    );
  };

  // TODO => add import whitelist from csv file
  // TODO => check whitelist validity
  return (
    <div>
      {phase.model === 'WalletCap' && phase.minAccessLevel.toString() !== '4' ? (
        <div className="whitelist-checkbox">
          <Checkbox
            checked={isWhitelistEnabled}
            onChange={() => setIsWhitelistEnabled(!isWhitelistEnabled)}
          />
          <span>Whitelist</span>
        </div>
      ) : null}
      {shouldDisplayWhitelist() ? (
        <div className="deal-edit-modal__content whitelist-content">
          <label>
            Whitelist
            <button
              type="button"
              onClick={() => setWhitelistPickerData({ whitelist: phase.whitelist, i: index })}
            >
              <SvgIcon name="iconDownloadPledge" width={16} height={16} />
            </button>
          </label>
          <Whitelist
            showAmount={phase.model === 'WalletCap'}
            whitelistId={`${phase.dealId}-${index}`}
            whitelist={phase.whitelist}
            onChange={(updatedWhitelist) => handlePhaseChange('whitelist', updatedWhitelist, index)}
            onValidityChange={() => null}
          />
        </div>
      ) : null}
      {phase.model === 'WalletCap' ? (
        <>
          <BdtLevelCapInput
            phaseLevels={phase.levels}
            setPhaseLevels={(updatedLevels) => handlePhaseChange('levels', updatedLevels, index)}
          />
          <div className="cap-container">
            <NumberInput
              label="Cap"
              subLabel="(optional)"
              value={phase.cap}
              onChange={(e) => handlePhaseChange('cap', e.target.value, index)}
              decimalNumber="2"
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default PhaseDetails;
