import { useMutation } from 'react-query';

import { removeRoleFromUser as apiRevokeRole } from 'services/apiService';

const useApiRevokeRole = (userAddress, { onSuccess, onError }) => {
  const { mutateAsync: revokeRole, isLoading } = useMutation(() => apiRevokeRole(userAddress), {
    onSuccess,
    onError,
  });

  return {
    revokeRole,
    isLoading,
  };
};

export default useApiRevokeRole;
