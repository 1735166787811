import React from 'react';
import { Dialog, Divider } from '@material-ui/core';
import { PledgeProvider } from './context';
import UpdatePledgeHeader from './components/UpdatePledgeHeader';
import UpdatePledgeControls from './components/UpdatePledgeControls';
import UpdatePledgeTable from './components/UpdatePledgeTable';

import './index.scss';
import UpdatePledgesFooter from './components/UpdatePledgesFooter';

const UpdatePledgesPopup = ({ deal, pledges: initialPledges, isOpen, onClose, onRefetch }) => {
  return (
    <PledgeProvider
      deal={deal}
      initialPledges={initialPledges}
      onClose={onClose}
      onRefetch={onRefetch}
    >
      <Dialog open={isOpen} onClose={onClose} maxWidth="md">
        <div className="claim-deal-check-modal">
          <UpdatePledgeHeader deal={deal} />
          <UpdatePledgeControls />
          <Divider />
          <UpdatePledgeTable />
          <UpdatePledgesFooter onClose={onClose} />
        </div>
      </Dialog>
    </PledgeProvider>
  );
};

export default UpdatePledgesPopup;
