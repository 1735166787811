import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { updateLoading, addNotification, setActiveHashes } from 'store/actions';

import { DEFAULT_CHAIN_NAME } from 'constants/config';
import notificationTypes from 'constants/notificationTypes';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import { MutationArgs } from 'utils/types';
import usePoolManager from './usePoolManager';

const useUpdatePoolPhase = (address, { onSuccess } = MutationArgs) => {
  const dispatch = useDispatch();

  const poolManager = usePoolManager();

  const { mutateAsync: updatePoolPhase, isLoading } = useMutation(
    async ({ phaseId, phaseConfig }) => {
      const tx = await poolManager.updatePoolPhase(address, phaseId, phaseConfig);

      const receipt = await tx.wait();

      const event = receipt.events.find((e) => e.event === 'PoolPhaseUpdated');

      if (!event) {
        throw new Error('Pool phase not updated');
      }

      return {
        receipt,
      };
    },
    {
      onMutate: () => {
        dispatch(updateLoading(true));
      },
      onSuccess: ({ receipt }) => {
        dispatch(updateLoading(false));

        dispatch(
          setActiveHashes([
            {
              chain: DEFAULT_CHAIN_NAME,
              hash: receipt.transactionHash,
              callback: () => {
                onSuccess();
              },
              pending: false,
            },
          ])
        );

        dispatch(
          addNotification({
            name: receipt.transactionHash,
            chain: DEFAULT_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
      },
      onError: () => {
        dispatch(updateLoading(false));
        dispatch(
          addNotification({
            name: 'Error while updating pool phase',
            status: 'error',
            statusText: 'Error!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
      },
    }
  );

  const checkedUpdatePoolPhase = useWithCorrectNetwork(updatePoolPhase);

  return {
    updatePoolPhase: checkedUpdatePoolPhase,
    isLoading,
  };
};

export default useUpdatePoolPhase;
