import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import React from 'react';

const UserRoleDialogHeader = ({ onCancel, onSave }) => (
  <div className="add-user-modal__header">
    <h2>
      <SvgIcon name="iconDealsModalEdit" />
      <span>Add new member</span>
    </h2>
    <div>
      <RoundedButton type="transparent" onClick={onCancel}>
        Cancel
      </RoundedButton>
      <RoundedButton type="primary" onClick={onSave}>
        Save
      </RoundedButton>
    </div>
  </div>
);

export default UserRoleDialogHeader;
