import React, { useMemo } from 'react';
import RoundedButton from 'components/button/rounded-button';

import { useNotifications } from 'hooks';
import { useQueryClient } from 'react-query';
import useCreatePoolPhases from 'contracts/poolManager/hooks/useCreatePoolPhases';
import useWithErrorNotifier from 'hooks/useWithErrorNotifier';
import { useDeployContractContext } from '../context';
import usePhaseMerkleRoot from '../../hooks/usePhaseMerkleRoot';
import { preparePhasesForDeploy } from '../helpers';

function CreatePhasesAction({ disabled, onValidate }) {
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const { phases, deal } = useDeployContractContext();

  const queryClient = useQueryClient();

  const { createPoolPhases, isLoading: isCreatingPhase } = useCreatePoolPhases(deal.address, {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', deal.id]);
      queryClient.invalidateQueries(['admin-deal', deal.id]);

      showSuccessNotification('Phase created successfully');
    },
  });

  const onSuccess = useWithErrorNotifier((result) => {
    const phases = preparePhasesForDeploy(result.phases);

    if (!phases) {
      return;
    }

    createPoolPhases({
      ...phases,
    });
  });

  const { insertMerkleRoots } = usePhaseMerkleRoot(deal.id, {
    onSuccess,
    onError: () => {
      showErrorNotification(
        'There are no users that satisfy the pro rata requirements for this phase'
      );
    },
  });

  const newPhases = useMemo(() => {
    const dealPhaseIndices = new Set(deal.phases.map((dealPhase) => dealPhase.index));

    return phases.filter((phase) => !dealPhaseIndices.has(phase.index));
  }, [deal.phases, phases]);

  const onCreate = () => {
    const { isFormValid } = onValidate();

    if (!isFormValid) {
      return;
    }

    insertMerkleRoots(newPhases);
  };

  return (
    <RoundedButton
      onClick={onCreate}
      disabled={isCreatingPhase || !newPhases.length || disabled}
      type="secondary"
    >
      Create
    </RoundedButton>
  );
}

export default CreatePhasesAction;
