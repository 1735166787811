import { useQuery } from 'react-query';
import { ethers } from 'ethers';
import { USDT_ADDRESS } from 'constants/config';
import useERC20Contract from './useERC20Contract';

const useERC20Allowance = (poolAddress) => {
  const erc20Contract = useERC20Contract(USDT_ADDRESS);

  const { data: allowance, isLoading } = useQuery(
    ['allowance', poolAddress],
    async () => {
      const address = await erc20Contract.signer.getAddress();

      const result = await erc20Contract.allowance(address, poolAddress);

      return ethers.utils.formatUnits(result, 'mwei');
    },
    {
      enabled: !!poolAddress,
    }
  );

  return { allowance, isLoading };
};

export default useERC20Allowance;
