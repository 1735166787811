import { useMutation } from 'react-query';
import { deleteRoleFromDb as apiDeleteRole } from 'services/apiService';

const useApiDeleteRole = (role, { onSuccess, onError }) => {
  const { mutateAsync: deleteRole, isLoading } = useMutation(() => apiDeleteRole(role.id), {
    onSuccess,
    onError,
  });

  return {
    deleteRole,
    isLoading,
  };
};

export default useApiDeleteRole;
