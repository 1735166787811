import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import {
  updateLoading,
  addNotification,
  setActiveHashes,
  setSharedNotification,
  updateGlobal,
} from 'store/actions';

import { DEFAULT_CHAIN_NAME } from 'constants/config';
import notificationTypes from 'constants/notificationTypes';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import { MutationArgs } from 'utils/types';
import usePoolContract from './usePoolContract';

const useContributePool = (address, { onSuccess, onError } = MutationArgs) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const poolContract = usePoolContract(address);

  const { mutateAsync: contribute, isLoading } = useMutation(
    async ({ phaseId, amount, allowListData }) => {
      const tx = await poolContract.contribute(phaseId, amount, allowListData);

      const receipt = await tx.wait();

      const event = receipt.events.find((e) => e.event === 'ContributionSubmitted');

      if (!event) {
        throw new Error('Contribution not submitted');
      }

      return {
        receipt,
      };
    },
    {
      onMutate: () => {
        dispatch(updateLoading(true));
      },
      onSuccess: ({ receipt }) => {
        dispatch(updateLoading(false));

        dispatch(updateGlobal({ activeDeal: null }));

        dispatch(
          setActiveHashes([
            {
              chain: DEFAULT_CHAIN_NAME,
              hash: receipt.transactionHash,
              callback: () => {
                queryClient.invalidateQueries(['my-contribution']);
                queryClient.invalidateQueries(['pool-info', address]);

                if (onSuccess) {
                  onSuccess();
                }
              },
              pending: false,
            },
          ])
        );

        dispatch(
          addNotification({
            name: receipt.transactionHash,
            chain: DEFAULT_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );

        dispatch(
          setSharedNotification({
            status: 'pending',
            title: 'Warning',
            description: 'Confirm TX via Hardware Wallet',
          })
        );
      },
      onError: () => {
        dispatch(updateLoading(false));
        dispatch(
          updateGlobal({
            dealApprovedStatus: 'contributeFailed',
          })
        );
        dispatch(
          addNotification({
            name: 'Error while creating pool',
            status: 'error',
            statusText: 'Error!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
        if (onError) {
          onError();
        }
      },
    }
  );

  const checkedContribute = useWithCorrectNetwork(contribute);

  return {
    contribute: checkedContribute,
    isLoading,
  };
};

export default useContributePool;
