import { getAdminDealInfo } from 'services/apiService';
import { useQuery } from 'react-query';

const useAdminDealQuery = (initDeal, { enabled } = {}) => {
  const { data, isFetching, refetch } = useQuery(
    ['admin-deal', initDeal.id],
    async () => {
      const deal = await getAdminDealInfo(initDeal.id);

      return {
        ...initDeal,
        ...deal,
        id: initDeal.id,
      };
    },
    { enabled }
  );

  return {
    deal: data || initDeal,
    isLoading: isFetching,
    refetch,
  };
};

export default useAdminDealQuery;
