import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DeleteRoleDialog from './DeleteRoleDialog';
import RoleList from './RoleList';
import RoleDetails from './details/RoleDetails';

const RoleManagementRoles = ({ roles, features }) => {
  const [state, setState] = useState({
    selectedRole: null,
    selectedRoleBeforeChange: null,
    selectedRoleToDelete: null,
    isDeleteModalOpen: false,
    isDealManagementSelected: false,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      isDealManagementSelected: !!prevState.selectedRoleBeforeChange?.maxCloseAmount,
    }));
  }, [state.selectedRoleBeforeChange]);

  const onClose = () => {
    setState({
      selectedRole: null,
      selectedRoleBeforeChange: null,
      selectedRoleToDelete: null,
      isDeleteModalOpen: false,
      isDealManagementSelected: false,
    });
  };

  const handleEditRoleIconClick = (role) => {
    setState((prevState) => ({
      ...prevState,
      selectedRole: role,
      selectedRoleBeforeChange: role,
    }));
  };

  const handleDeleteRoleIconClick = (role) => {
    setState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      selectedRoleToDelete: role,
    }));
  };

  return (
    <Grid container>
      <DeleteRoleDialog
        open={state.isDeleteModalOpen}
        role={state.selectedRoleToDelete}
        onClose={onClose}
      />
      <RoleList
        roles={roles}
        onCreateNew={() =>
          setState((prevState) => ({
            ...prevState,
            selectedRole: { name: '', maxCloseAmount: '', featuresIds: [] },
            selectedRoleBeforeChange: null,
          }))
        }
        onEdit={handleEditRoleIconClick}
        onDelete={handleDeleteRoleIconClick}
      />
      <Grid item xs={8}>
        {state.selectedRole && (
          <RoleDetails
            role={{
              ...state.selectedRole,
              initMaxCloseAmount: state.selectedRoleBeforeChange?.maxCloseAmount || '',
            }}
            setRole={(role) => setState((prevState) => ({ ...prevState, selectedRole: role }))}
            features={features}
            isDealManagementSelected={state.isDealManagementSelected}
            setIsDealManagementSelected={(isSelected) =>
              setState((prevState) => ({ ...prevState, isDealManagementSelected: isSelected }))
            }
            onClose={onClose}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default RoleManagementRoles;
