import { useNotifications } from 'hooks';

import { useQueryClient } from 'react-query';
import useAssignRole from 'contracts/poolManager/hooks/roles/useAssignRole';
import useApiAssignRole from './api/useApiAssignRole';

const useRoleAssign = (assignData, { onSuccess, onError }) => {
  const [showSuccessNotification] = useNotifications();

  const queryClient = useQueryClient();

  const { assignRole: apiAssignRole, isLoading: isApiAssigningRole } = useApiAssignRole(
    assignData,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users-roles']);

        showSuccessNotification('Role assigned successfully');
        onSuccess();
      },
      onError,
    }
  );

  const { executeMethod: contractAssignRole, isLoading: isContractRoleAssigning } = useAssignRole(
    assignData,
    {
      onSuccess: apiAssignRole,
      onError,
    }
  );

  return {
    assignRole: contractAssignRole,
    isLoading: isApiAssigningRole || isContractRoleAssigning,
  };
};

export default useRoleAssign;
