import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { ethers } from 'ethers';
import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';
import useBatchReleasePledge from 'contracts/pledgeVault/hooks/useBatchReleasePledge';
import { useQueryClient } from 'react-query';
import { StateField } from '../constants';
import { buildInitialState, calculateNewReleaseAmounts, sumPledges } from '../helpers';
import distributeAmountAmongPledges from '../distributions';

const PledgeContext = createContext();

export const usePledgeContext = () => useContext(PledgeContext);

export const PledgeProvider = ({ deal, initialPledges, onClose, onRefetch, children }) => {
  const [pledges, setPledges] = useState([]);

  const [state, setState] = useState({});

  const { totalRelease, isManualInput, isDistributeEvenly } = state;

  const { pool } = usePledgePoolQuery(deal.address);

  const queryClient = useQueryClient();

  const { batchReleasePledge, isLoading } = useBatchReleasePledge(deal.address, {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', deal.id, 'pledge']);
      queryClient.invalidateQueries(['admin-deal', deal.id]);
      onClose();
      onRefetch();
    },
    onError: onClose,
  });

  const originalPledgedAmount = ethers.utils.formatUnits(pool?.totalPledgedAmount || 0, 'mwei');

  const updateTotalReleaseState = ({ amount = 0, isDirty, isDistributeEvenly }) => {
    const safeAmount = amount || 0;

    const releaseAmount = +originalPledgedAmount - +safeAmount;

    const refund = +originalPledgedAmount - +releaseAmount;

    setState((prevState) => ({
      ...prevState,
      totalRelease: {
        isDirty,
        amount: releaseAmount,
        amountFormatted: parseFloat(releaseAmount).toFixed(1),
        refund: parseFloat(refund).toFixed(1),
        distribute: amount,
      },
      isDistributeEvenly,
    }));
  };

  const updateState = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const invariantNewTotalRelease = (newTotalRelease, onValid) => {
    if (+newTotalRelease > +originalPledgedAmount) {
      return updateState(
        StateField.Error,
        'Release amount cannot be greater than total pledged amount'
      );
    }

    updateState(StateField.Error, '');
    onValid();
  };

  const onApplyAll = () => {
    updateState(StateField.IsManualInput, false);
    updateTotalReleaseState({
      amount: sumPledges(pledges).toFixed(1),
      isDirty: false,
    });
  };

  const onApplySinglePledge = (index, e) => {
    const { value } = e.target;

    const { newTotalReleaseAmount, newAmount } = calculateNewReleaseAmounts({
      pledges,
      totalRelease,
      value,
      index,
    });

    invariantNewTotalRelease(newTotalReleaseAmount, () => {
      pledges[index].newAmount = newAmount;

      setPledges(pledges);
      updateTotalReleaseState({
        amount: newTotalReleaseAmount,
        isDirty: true,
        isDistributeEvenly: false,
      });
    });
  };

  const onUpdateTotalRelease = (e) => {
    const { value } = e.target;
    const newTotalRelease = value.replace(/,/g, '.');

    invariantNewTotalRelease(newTotalRelease, () => {
      updateTotalReleaseState({
        amount: newTotalRelease,
        isDirty: true,
        isDistributeEvenly: false,
      });
    });
  };

  const onToggleManualInput = () => {
    const nextIsManualInput = !isManualInput;

    if (nextIsManualInput) {
      const newAmount = sumPledges(pledges);

      updateTotalReleaseState({ amount: newAmount.toFixed(1), isDirty: true });
    }

    updateState(StateField.IsManualInput, nextIsManualInput);
  };

  const onToggleDistributeEvenly = () => {
    const nextIsDistributeEvenly = !isDistributeEvenly;

    if (!isDistributeEvenly) {
      const { updatedPledges } = distributeAmountAmongPledges({
        totalInitialPledge: originalPledgedAmount,
        amountToDistribute: totalRelease.distribute || originalPledgedAmount,
        pledges,
      });

      setPledges(updatedPledges);
      updateTotalReleaseState({ amount: totalRelease.distribute, isDirty: true });
    }

    updateState(StateField.IsDistributeEvenly, nextIsDistributeEvenly);
  };

  const initState = useCallback(() => {
    const initialState = buildInitialState(originalPledgedAmount);
    const deepCopy = initialPledges.map((pledge) => ({ ...pledge, newAmount: pledge.amount }));

    setPledges(deepCopy);

    setState(initialState);
  }, [initialPledges, originalPledgedAmount]);

  useEffect(() => {
    initState();
  }, [initState]);

  return (
    <PledgeContext.Provider
      value={{
        pledges,
        state,
        isLoading,
        onApplyAll,
        onApplySinglePledge,
        onUpdateTotalRelease,
        onToggleManualInput,
        onToggleDistributeEvenly,
        originalPledgedAmount,
        batchReleasePledge,
        initState,
      }}
    >
      {children}
    </PledgeContext.Provider>
  );
};
