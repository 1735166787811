import CustomInput from 'components/input/custom-input';
import React from 'react';

const UserInputFields = ({ userRoleToAdd, setUserRoleToAdd, userToEdit }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserRoleToAdd((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="add-user-modal__content">
      <CustomInput
        label="User Name"
        name="username"
        value={userRoleToAdd.username}
        onChange={handleChange}
      />
      <CustomInput label="Email" name="email" value={userRoleToAdd.email} onChange={handleChange} />
      <CustomInput
        label="Account"
        name="address"
        value={userRoleToAdd.address}
        disabled={!!userToEdit?.wallet}
        onChange={handleChange}
      />
    </div>
  );
};

export default UserInputFields;
