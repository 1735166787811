import React from 'react';
import SvgIcon from 'components/svgIcon';
import WhitelistPickerModal from 'features/whitelists/WhitelistPickerModal';
import { useDeployContractContext } from '../context';
import { InitialPhaseState } from '../constants';

function DeployContractFooter() {
  const {
    whitelistPickerData,
    setWhitelistPickerData,
    handlePhaseChange,
    setPhases,
  } = useDeployContractContext();

  return (
    <>
      <WhitelistPickerModal
        open={!!whitelistPickerData}
        onClose={() => setWhitelistPickerData(null)}
        onSave={(newAddresses) =>
          handlePhaseChange(
            'whitelist',
            [
              ...whitelistPickerData.whitelist,
              ...newAddresses.map((address) => ({ address, personalCap: 0 })),
            ],
            whitelistPickerData.i
          )
        }
      />
      <div className="add-phase" onClick={() => setPhases((prev) => [...prev, InitialPhaseState])}>
        <SvgIcon name="plus" />
      </div>
    </>
  );
}

export default DeployContractFooter;
