import React from 'react';
import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';
import PledgeStatus from 'contracts/pledgeVault/constants';
import PledgeTxList from './PledgeTxList';
import PledgeControl from './PledgeControl';
import './UpdatePledges/UpdatePledgesPopoup/index.scss';
import UpdatePledgesButton from './UpdatePledges/UpdatePledgesButton';
import PledgeAnalyticsModal from './PledgeAnalyticsModal';
import PledgeStats from './PledgeStats';

const PledgeTab = ({ deal, pledges, onRefetch }) => {
  const { pool } = usePledgePoolQuery(deal.address);

  return (
    <div className="content pledge-content">
      <PledgeAnalyticsModal deal={deal} />

      <div className="content-row">
        <div className="col col-4 pledge-left transparent p-0">
          <PledgeControl deal={deal} />
          <PledgeStats pledges={pledges} deal={deal} />
        </div>

        <div className="col col-8">
          <div className="pledge-transactions-top">
            <div className="pledge-transactions-title-holder">
              <h3>List of pledges</h3>
              {pool?.status === PledgeStatus.Freezed ? (
                <UpdatePledgesButton
                  deal={deal}
                  pool={pool}
                  pledges={pledges}
                  onRefetch={onRefetch}
                />
              ) : null}
            </div>
          </div>
          <PledgeTxList deal={deal} pledges={pledges} />
        </div>
      </div>
    </div>
  );
};
export default PledgeTab;
