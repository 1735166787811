import CustomSelect from 'components/select/custom-select';
import React from 'react';

const RoleSelection = ({ roles, userRoleToAdd, setUserRoleToAdd }) => {
  const handleRoleSelect = (name, value) => {
    const selectedRole = roles.find((role) => role.name === value);

    setUserRoleToAdd((prev) => ({ ...prev, [name]: selectedRole }));
  };

  return (
    <CustomSelect
      label="Role"
      items={roles.map(({ name }) => ({ name, value: name }))}
      name="role"
      value={userRoleToAdd.role.name}
      onSelect={handleRoleSelect}
      fixed
      maxHeight="12vh"
    />
  );
};

export default RoleSelection;
