import React from 'react';
import NumberInput from 'components/input/number-input';
import { shortenAddress } from 'utils';
import { useMediaQuery } from '@material-ui/core';
import { usePledgeContext } from '../context';

const UpdatePledgeTable = () => {
  const { pledges, onApplySinglePledge, state } = usePledgeContext();

  const isMobile = useMediaQuery('(max-width: 767px)');

  const isInputDisabled = !state.isManualInput;

  return (
    <div className="claim-deal-check-modal__table">
      <div className="claim-deal-check-modal__table-head">
        <span>Wallet</span>
        <span>Pledge Amount</span>
        <span>New Amount</span>
      </div>
      <div className="claim-deal-check-modal__table-body">
        {pledges.map((pledge, index) => (
          <div key={pledge.wallet}>
            <span>{isMobile ? shortenAddress(pledge.wallet) : pledge.wallet}</span>
            <span>{pledge.amount}</span>
            <NumberInput
              value={!isInputDisabled ? pledge.newAmount : parseFloat(pledge.newAmount).toFixed(1)}
              onChange={(e) => onApplySinglePledge(index, e)}
              decimalNumber="2"
              max={+pledge.amount}
              disabled={isInputDisabled}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpdatePledgeTable;
