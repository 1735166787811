import React from 'react';
import { DealStatus } from 'models/constants';
import './index.scss';

import PledgeVersionSwitch from './PledgeVersionSwitch';
import OldPledgeControl from './versions/v1/OldPledgeControl';
import NewPledgeControl from './versions/v2/NewPledgeControl';
import PoolTooltip from './versions/v2/PoolTooltip';

const PledgeControl = ({ deal }) => {
  return (
    <div className="col col-12 pledge-control">
      <div className="horizontal-flex horizontal-flex-center">
        <p>Pledge Control</p>
        <PoolTooltip deal={deal} />
      </div>
      <div className="pledge-control-content">
        {deal.useOldPledge ? <OldPledgeControl deal={deal} /> : <NewPledgeControl deal={deal} />}
        {deal.statusId === DealStatus.None ? (
          <PledgeVersionSwitch dealId={deal.id} initialValue={deal.useOldPledge} />
        ) : null}
      </div>
    </div>
  );
};

export default PledgeControl;
