import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import ContractUsersTableRow from './ContractUsersTableRow';

const ContractUsersTable = ({ contractRoles }) => {
  return (
    <>
      <h2 className="contract-table-title">Contract Users:</h2>
      <TableContainer classes={{ root: 'users-table' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>NAME</TableCell>
              <TableCell>WALLET</TableCell>
              <TableCell>ROLE NAME</TableCell>
              <TableCell>MAX CLOSE AMOUNT</TableCell>
              <TableCell>CREATED AT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contractRoles.map(({ id, roleName, maxCloseAmount, AssignedDealFactoryRole }) =>
              AssignedDealFactoryRole?.map((data) => (
                <ContractUsersTableRow
                  key={id}
                  data={{
                    ...data,
                    roleName,
                    maxCloseAmount,
                  }}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ContractUsersTable;
