import React from 'react';
import ContractRolesTable from './ContractRoleTable';
import ContractUsersTable from './ContractUsersTable';

const RoleManagementContract = ({ contractRoles }) => {
  return (
    <>
      <ContractRolesTable contractRoles={contractRoles} />
      <ContractUsersTable contractRoles={contractRoles} />
    </>
  );
};

export default RoleManagementContract;
