import { getOldDealModels } from 'models/oldDealModel';
import { useQuery } from 'react-query';
import { getAllOldDeals } from 'services/apiService';

export default function useOldDealsQuery(authState, { onSuccess, onError, enabled }) {
  const { data: deals, isFetching } = useQuery(
    ['old-deals'],
    async () => {
      const oldDealsApi = await getAllOldDeals();

      return getOldDealModels(authState.accountInfo.address, oldDealsApi);
    },
    {
      onSuccess,
      onError,
      enabled,
    }
  );

  return {
    deals,
    isLoading: isFetching,
  };
}
