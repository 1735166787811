import { useMutation } from 'react-query';
import { updateRole as apiUpdateRole } from 'services/apiService';

const useApiUpdateRole = (role, { onSuccess, onError }) => {
  const { mutateAsync: updateRole, isLoading } = useMutation(() => apiUpdateRole(role), {
    onSuccess,
    onError,
  });

  return {
    updateRole,
    isLoading,
  };
};

export default useApiUpdateRole;
