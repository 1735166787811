import React, { useState, useEffect, useMemo } from 'react';
import SvgIcon from 'components/svgIcon';
import { thousandSeparatorRound, formatOverviewText } from 'utils/helpers';
import Image from 'components/Image';
import { useSelector } from 'react-redux';
import Tooltip from 'components/tooltip';
import RoundedButton from 'components/button/rounded-button';
import './index.scss';
import { useHistory } from 'react-router-dom';
import DOMPurify from 'dompurify';
import partnersRemote from 'features/deals/PartnersModal/remote';
import teamRemote from 'features/deals/TeamModal/remote';
import statsRemote from 'features/deals/RaiseStatsModal/remote';

const DealInfoTab = ({ deal, open }) => {
  const history = useHistory();
  const authReducer = useSelector((state) => state.auth);
  const { profileWallets } = authReducer;

  const [highlightsData, setHighlightsData] = useState();
  const [overviewData, setOverviewData] = useState();

  const { data: partnersInvestors } = partnersRemote.useGetDealPartnersInvestors(deal.id, open);
  const { data: teamAdvisors } = teamRemote.useGetDealTeamAdvisors(deal.id, open);
  const { data: raiseStats } = statsRemote.useGetDealRaiseStats(deal.id, open);

  useEffect(() => {
    let overviewText = deal?.overview || '';
    let highlightsText = deal?.highlights || '';

    overviewText = formatOverviewText(overviewText);
    highlightsText = formatOverviewText(highlightsText);

    setOverviewData(overviewText || '');
    setHighlightsData(highlightsText || '');
  }, [deal.highlights, deal.overview]);

  const isRequiredWalletMissing = useMemo(
    () => deal.requiredChain && !profileWallets?.some(({ chain }) => chain === deal.requiredChain),
    [deal, profileWallets]
  );

  return (
    <div className="content info-content deal-info-tab">
      <div className="content-row">
        <div className="col col-4 overview-col">
          <h3 className="col-title">OVERVIEW</h3>
          <div className="html-holder" tabIndex="0">
            <div
              className="inner-content"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(overviewData) }}
            />
          </div>
          {deal.projectUrl && (
            <a
              className="external-link"
              href={deal.projectUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              Project details{' '}
              <span>
                <SvgIcon name="iconExternalLink" />
              </span>
            </a>
          )}
        </div>
        <div className="col col-4">
          <h3 className="col-title">HIGHLIGHTS</h3>
          <div className="html-holder" tabIndex="0">
            <div
              className="inner-content"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(highlightsData) }}
            />
          </div>
        </div>
        <div className="col col-4">
          <h3 className="col-title">BASIC METRICS</h3>
          <div className="metrics-holder">
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Ticker:</p>
              </div>
              <div className="box-col col-right">
                <p>{deal.ticker || '-'}</p>
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Token Type:</p>
              </div>
              <div className="box-col col-right token-type-col">
                <p>{deal.tokenType || '-'} </p>
                {isRequiredWalletMissing && (
                  <Tooltip
                    interactive
                    classes={{ popper: 'token-type-tooltip' }}
                    title={
                      <>
                        <p>The native wallet is required!</p>
                        <p>Please {deal.requiredChain} wallet.</p>
                        <RoundedButton onClick={() => history.push('/profile')}>
                          Change the wallet
                          <SvgIcon name="arrowRight" width={16} height={16} />
                        </RoundedButton>
                      </>
                    }
                  >
                    <div>
                      <SvgIcon name="error" />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>TGE:</p>
              </div>
              <div className="box-col col-right">
                <p>{deal.tge || '-'}</p>
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Total Raise:</p>
              </div>
              <div className="box-col col-right">
                {deal.hardCap ? (
                  <p>{`${
                    deal.hardCapSymbol === '$' ? deal.hardCapSymbol : ''
                  } ${thousandSeparatorRound(deal.hardCap)}${
                    deal.hardCapSymbol !== '$' ? ` ${deal.hardCapSymbol}` : ''
                  }`}</p>
                ) : (
                  <p>-</p>
                )}
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Initial Circulating Supply:</p>
              </div>
              <div className="box-col col-right">
                <p>{deal.initCircSupply ? thousandSeparatorRound(deal.initCircSupply) : '-'}</p>
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Initial Market Cap:</p>
              </div>
              <div className="box-col col-right">
                <p>{deal.initMarketCap ? `$${thousandSeparatorRound(deal.initMarketCap)}` : '-'}</p>
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Total Supply:</p>
              </div>
              <div className="box-col col-right">
                <p>{deal.totalySupply ? thousandSeparatorRound(deal.totalySupply) : '-'}</p>
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Fully Diluted Market Cap:</p>
              </div>
              <div className="box-col col-right">
                <p>
                  {deal.fullyDilutedMarketCap
                    ? `$${thousandSeparatorRound(deal.fullyDilutedMarketCap)}`
                    : '-'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-row">
        <div className="col col-4">
          <h3 className="col-title">BLACKDRAGON DEAL</h3>
          <div className="blackdragon-deal">
            <div className="deal-box">
              <div className="icon">
                <SvgIcon name="iconDealInfoAmount" />
              </div>
              <div className="box-info">
                <p className="info-label">Price</p>
                <p className="info-value">{deal.price ? `$${deal.price}` : '/'}</p>
              </div>
            </div>
            <div className="deal-box">
              <div className="icon">
                <SvgIcon name="iconDealInfoLockup" />
              </div>
              <div className="box-info">
                <p className="info-label">Round</p>
                <p className="info-value">{deal.round || '/'}</p>
              </div>
            </div>
            <div className="deal-box">
              <div className="icon">
                <SvgIcon name="iconDealInfoLockup" />
              </div>
              <div className="box-info">
                <p className="info-label">Vesting</p>
                <p className="info-value">{deal.vesting || '/'}</p>
              </div>
            </div>
            <div className="deal-box">
              <div className="icon">
                <SvgIcon name="iconDealInfoAmount" />
              </div>
              <div className="box-info">
                <p className="info-label">Fee</p>
                <p className="info-value">{deal.fee ? `${deal.fee}%` : '/'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-8">
          <h3 className="col-title">RAISE STATS</h3>
          <div className="table-holder">
            <table>
              <thead>
                <tr>
                  <td colSpan="2">Round</td>
                  <td>Token Price</td>
                  <td>Tokens for Sale</td>
                  <td colSpan="3">Vesting</td>
                  <td>Amount</td>
                </tr>
              </thead>
              <tbody>
                {deal.dealRaiseStats?.map((item) => {
                  if (!item) return null;
                  return (
                    <tr key={item.id} className="raise-stats-row">
                      <td data-label="Round" colSpan="2">
                        {item.round}
                      </td>
                      <td data-label="Token Price">{item.tokenPrice}</td>
                      <td data-label="Tokens for Sale">{item.tokensSold}</td>
                      <td data-label="Vesting" colSpan="3">
                        {item.vesting}
                      </td>
                      <td data-label="Amount">{item.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="content-row">
        <div className="col col-8 partners-and-investors-container">
          <h3 className="col-title">PARTNERS &amp; INVESTORS</h3>
          <div className="partners-and-investors">
            {partnersInvestors?.map((item) => {
              if (!item.logo) return null;
              return (
                <div className="partners-and-investors-box-holder" key={item.id}>
                  <div className="partners-and-investors-box">
                    <a href={item.website} target="_blank" rel="noopener noreferrer">
                      <Image src={item.logo} alt="Partners and investors logo" />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col col-4 transparent p-0">
          <div className="content-row m-0">
            <div className="col col-12">
              <h3 className="col-title">TEAM &amp; ADVISORS</h3>
              <div className="team-and-advisors">
                {teamAdvisors?.map((item) => {
                  if (!item.logo) return null;
                  if (!item.website) {
                    return (
                      <div className="img-holder" key={item.id}>
                        <Image src={item.logo} alt="Team member" />
                      </div>
                    );
                  }
                  return (
                    <a
                      className="img-holder"
                      href={item.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={item.key}
                    >
                      <Image src={item.logo} alt="Team member" />
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="col col-12">
              <h3 className="col-title">PROJECT LINKS</h3>
              <div className="project-links">
                {deal && deal.projectLinks && deal.projectLinks[0] && (
                  <a
                    href={deal.projectLinks[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconDealPitchDeck" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[1] && (
                  <a
                    href={deal.projectLinks[1]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconDealWhitepaper" />
                  </a>
                )}
                <span className="img-holder">
                  <span className="vertical-line">|</span>
                </span>
                {deal && deal.projectLinks && deal.projectLinks[2] && (
                  <a
                    href={deal.projectLinks[2]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksWebsite" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[3] && (
                  <a
                    href={deal.projectLinks[3]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksTwitter" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[4] && (
                  <a
                    href={deal.projectLinks[4]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksTelegram" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[5] && (
                  <a
                    href={deal.projectLinks[5]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksDiscord" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[6] && (
                  <a
                    href={deal.projectLinks[6]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksBlog" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[7] && (
                  <a
                    href={deal.projectLinks[7]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksLink" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[8] && (
                  <a
                    href={deal.projectLinks[8]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksLink" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealInfoTab;
