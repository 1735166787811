import { openPledge } from 'services/apiService';
import { useQueryClient, useMutation } from 'react-query';
import { useNotifications } from 'hooks';

function useOldOpenPledge(dealId) {
  const queryClient = useQueryClient();

  const [showSuccessNotification, showErrorNotification] = useNotifications();

  return useMutation(() => openPledge(dealId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-deal', dealId]);

      showSuccessNotification('Pledge opened');
    },
    onError: () => {
      showErrorNotification('Failed to open pledge');
    },
  });
}

export default useOldOpenPledge;
