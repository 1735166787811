import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import {
  updateLoading,
  addNotification,
  setActiveHashes,
  setSharedNotification,
} from 'store/actions';

import { DEFAULT_CHAIN_NAME } from 'constants/config';
import notificationTypes from 'constants/notificationTypes';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import { MutationArgs } from 'utils/types';
import usePoolManager from './usePoolManager';

const useClosePool = ({ onSuccess, onError } = MutationArgs) => {
  const dispatch = useDispatch();

  const poolManager = usePoolManager();

  const { mutateAsync: closePool, isLoading } = useMutation(
    async ({ address, wallets, amounts }) => {
      console.log('address, wallets, amounts:', address, wallets, amounts);
      const tx = await poolManager.closePool(address, wallets, amounts);

      const receipt = await tx.wait();

      const event = receipt.events.find((e) => e.event === 'PoolClosed');

      if (!event) {
        throw new Error('Pool not created');
      }

      return {
        receipt,
        pool: event.args.pool,
      };
    },
    {
      onMutate: () => {
        dispatch(updateLoading(true));
      },
      onSuccess: ({ receipt, pool }) => {
        dispatch(updateLoading(false));

        dispatch(
          setActiveHashes([
            {
              chain: DEFAULT_CHAIN_NAME,
              hash: receipt.transactionHash,
              callback: () => {
                onSuccess(pool);
              },
              pending: false,
            },
          ])
        );

        dispatch(
          addNotification({
            name: receipt.transactionHash,
            chain: DEFAULT_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );

        dispatch(
          setSharedNotification({
            status: 'pending',
            title: 'Warning',
            description: 'Confirm TX via Hardware Wallet',
          })
        );
      },
      onError: () => {
        dispatch(updateLoading(false));
        dispatch(
          addNotification({
            name: 'Error while creating pool',
            status: 'error',
            statusText: 'Error!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
        onError();
      },
    }
  );

  const checkedClosePool = useWithCorrectNetwork(closePool);

  return {
    closePool: checkedClosePool,
    isLoading,
  };
};

export default useClosePool;
