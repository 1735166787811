import React from 'react';
import { Divider } from '@material-ui/core';
import { DealVersion } from 'models/constants';
import DeployContractHeader from './components/DeployContractHeader';
import DeployContractFooter from './components/DeployContractFooter';
import PhaseList from './phases/PhaseList';
import './index.scss';
import { DeployContractProvider } from './context';
import DeployBlurContainer from './components/DeployBlurContainer';

const DeployContractTab = ({ deal }) => {
  if (deal.version !== DealVersion.V3)
    return (
      <p style={{ width: '100%', textAlign: 'center', margin: '20px 0' }}>
        Old deal phases are no longer supported
      </p>
    );

  return (
    <DeployContractProvider deal={deal}>
      <DeployBlurContainer>
        <div className="deploy-contract">
          <DeployContractHeader />
          <Divider />
          <PhaseList />
          <DeployContractFooter />
        </div>
      </DeployBlurContainer>
    </DeployContractProvider>
  );
};

export default DeployContractTab;
