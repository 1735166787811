import { useQueryClient } from 'react-query';
import { POOL_MANAGER_ADDRESS } from 'constants/config';

import useContractMethod from 'contracts/common/useContractMethod';
import { calculateKeccak256 } from 'utils';
import usePoolManager from '../usePoolManager';

const useAssignRole = (assignData, { onSuccess, onError }) => {
  const queryClient = useQueryClient();

  const poolManager = usePoolManager(POOL_MANAGER_ADDRESS);

  return useContractMethod(
    () => {
      const rawRole = calculateKeccak256(assignData.role.name);

      return poolManager.assignRoleToAccount(assignData.address, rawRole);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['roles']);
        onSuccess();
      },
      onError,
      listenerOptions: {
        eventName: 'RoleAssignedToAccount',
        errorMessage: 'Role not assigned to account',
      },
    }
  );
};

export default useAssignRole;
