import { useQuery } from 'react-query';
import { getUsersWithRole } from 'services/apiService';

const useUsersWithRoles = () => {
  const { data, isFetching } = useQuery(['users-roles'], getUsersWithRole);

  return {
    users: data,
    isLoading: isFetching,
  };
};

export default useUsersWithRoles;
