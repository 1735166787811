import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import UserManageDropdown from '../UserManageDropdown';

const UserRoleTableRow = ({ user, onEdit }) => {
  const { id, name, email, role, joined, wallet } = user;

  return (
    <TableRow key={id}>
      <TableCell scope="row" classes={{ root: 'username-column' }}>
        <RoundedAvatar
          src={`https://api.dicebear.com/7.x/bottts/svg?seed=${wallet.toLowerCase()}&background=%2370bceb&size=100`}
        />
        {name || 'Unassigned'}
      </TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{wallet}</TableCell>
      <TableCell>{role}</TableCell>
      <TableCell>{moment(joined).format('DD-MMM-YYYY')}</TableCell>
      <TableCell align="right">
        <div className="more-icon-container">
          <UserManageDropdown wallet={wallet} role={role} onEdit={() => onEdit(user)} />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default UserRoleTableRow;
