import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { updateLoading, setActiveHashes } from 'store/actions';

import { DEFAULT_CHAIN_NAME } from 'constants/config';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import usePledgeVault from 'contracts/pledgeVault/hooks/usePledgeVault';
import { MutationArgs } from 'utils/types';

const useUpdatePledgeStatus = (address, { onSuccess, onError } = MutationArgs) => {
  const dispatch = useDispatch();

  const pledgeVault = usePledgeVault();

  const queryClient = useQueryClient();

  const { mutateAsync: updatePledgeStatus, isLoading } = useMutation(
    async (newStatus) => {
      if (!address) {
        throw new Error('Pledge vault not initialized!');
      }

      const tx = await pledgeVault.updatePledgeStatus(address, newStatus);

      const receipt = await tx.wait();

      const event = receipt.events.find((e) => e.event === 'PledgeStatusUpdated');

      if (!event) {
        throw new Error('Pledge status not updated');
      }

      return {
        receipt,
        pool: event.args.pool,
      };
    },
    {
      onMutate: () => {
        dispatch(updateLoading(true));
      },
      onSuccess: ({ receipt, pool }) => {
        dispatch(updateLoading(false));
        dispatch(
          setActiveHashes([
            {
              chain: DEFAULT_CHAIN_NAME,
              hash: receipt.transactionHash,
              callback: () => {
                queryClient.invalidateQueries(['pool', pool]);
                queryClient.invalidateQueries(['pool-info', pool]);
                onSuccess();
              },
              pending: false,
            },
          ])
        );
      },
      onError: () => {
        dispatch(updateLoading(false));
        onError();
      },
    }
  );

  const checkedUpdatePledgeStatus = useWithCorrectNetwork(updatePledgeStatus);

  return {
    updatePledgeStatus: checkedUpdatePledgeStatus,
    isLoading,
  };
};

export default useUpdatePledgeStatus;
