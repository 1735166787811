import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import CustomSelect from 'components/select/custom-select';
import CustomInput from 'components/input/custom-input';
import DatePicker from 'react-datepicker';
import SvgIcon from 'components/svgIcon';
import IconButton from 'components/button/icon-button';
import PhaseDetails from './PhaseDetails';
import { AllocationModelOptions, LevelOptions } from '../constants';

import { useDeployContractContext } from '../context';
import UpdatePhaseAction from '../actions/UpdatePhase';

const PhaseItem = ({ index, phase }) => {
  const { toggleExpand, handlePhaseChange, setPhases } = useDeployContractContext();

  console.log('phase:', phase);

  return (
    <Accordion
      classes={{ root: 'deal-edit-modal accordion', rounded: 'accordion__rounded' }}
      expanded={!!phase.expanded}
    >
      <AccordionSummary
        onClick={() => toggleExpand(index)}
        classes={{
          content: 'accordion-summary__content',
          root: 'accordion-summary',
          focusVisible: 'accordion-summary__focused',
        }}
        expandIcon={<SvgIcon name="arrowDown" />}
      >
        <label>{index + 1}. </label>
        <CustomSelect
          items={LevelOptions}
          name="minAccessLevel"
          value={phase.minAccessLevel}
          onSelect={(name, value) => handlePhaseChange(name, value, index)}
        />
        <div className="phase-name" onClick={(e) => e.stopPropagation()}>
          <CustomInput
            value={phase.phaseName}
            onChange={(e) => handlePhaseChange('phaseName', e.target.value, index)}
          />
        </div>
        <CustomSelect
          items={AllocationModelOptions}
          name="model"
          value={phase.model}
          onSelect={(name, value) => handlePhaseChange(name, value, index)}
        />
        <div onClick={(e) => e.stopPropagation()} className="date-picker__container">
          <DatePicker
            selected={phase.startDate}
            showTimeSelect
            onChange={(date) => handlePhaseChange('startDate', date, index)}
            dateFormat="MM/dd HH:mm"
          />
        </div>
        <div onClick={(e) => e.stopPropagation()} className="date-picker__container">
          <DatePicker
            selected={phase.endDate}
            showTimeSelect
            onChange={(date) => handlePhaseChange('endDate', date, index)}
            dateFormat="MM/dd HH:mm"
          />
        </div>
        <CustomSelect
          items={LevelOptions}
          name="minViewLevel"
          value={phase.minViewLevel}
          onSelect={(name, value) => handlePhaseChange(name, value, index)}
        />
        <UpdatePhaseAction phase={phase} />
        <div className="delete-icon">
          <IconButton
            icon="iconDeleteX"
            onClick={() => {
              setPhases((prev) => prev.filter((_, i) => i !== index));
            }}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: 'accordion-details' }}>
        <PhaseDetails phase={phase} index={index} />
      </AccordionDetails>
    </Accordion>
  );
};

export default PhaseItem;
