import React from 'react';
import InfoTooltip from 'components/tooltip/custom/InfoTooltip';
import PledgeStatusDescription from '../../PledgeStatusDescription';
import withNewPledge from '../../../hoc/withNewPledge';

function PoolTooltip() {
  return <InfoTooltip title={<PledgeStatusDescription />} placement="right-start" />;
}

export default withNewPledge(PoolTooltip);
