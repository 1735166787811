import { ethers } from 'ethers';
import { useMemo } from 'react';
import { getProviderByChainId } from 'contracts/providers';
import { DEFAULT_CHAIN_ID } from 'constants/config';
import IPool from '../../poolManager/abi/IPool.json';

const useReadonlyPool = (address) => {
  return useMemo(() => {
    if (!address) return;

    const provider = getProviderByChainId(DEFAULT_CHAIN_ID);

    if (!provider) {
      throw Error('Provider missing');
    }

    return new ethers.Contract(address, IPool, provider);
  }, [address]);
};

export default useReadonlyPool;
