import { useQuery, useQueryClient } from 'react-query';
import { useNotifications } from 'hooks';

import { useSelector } from 'react-redux';
import { ethers } from 'ethers';
import { DealVersion } from 'models/constants';
import usePoolContract from './usePoolContract';

const useMyContributionQuery = (deal, options = {}) => {
  const walletAddress = useSelector((state) => state.auth.walletAddress);

  const queryClient = useQueryClient();

  const [, showErrorNotification] = useNotifications();

  const poolContract = usePoolContract(deal?.address);

  const { data: myContribution, isFetching } = useQuery(
    ['my-contribution', deal?.address],
    async () => {
      const contributionsBN = await poolContract.userContributions(walletAddress);

      const contributions = ethers.utils.formatUnits(contributionsBN, 'mwei');

      return {
        formatted: contributions,
        raw: contributionsBN,
      };
    },
    {
      ...options,
      enabled: !!deal?.address && deal.version === DealVersion.V3,
      onSuccess: ({ formatted }) => {
        const dealToUpdate = queryClient.getQueryData(['deal', deal.id]);

        if (!dealToUpdate) {
          return;
        }

        dealToUpdate.contributedAmount = formatted;

        queryClient.setQueryData(['deal', deal.id], dealToUpdate);
      },
      onError: () => {
        showErrorNotification('Error fetching my contribution');
      },
    }
  );

  return {
    myContribution: myContribution?.formatted || 0,
    isLoading: isFetching,
  };
};

export default useMyContributionQuery;
