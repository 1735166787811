import React from 'react';
import RoundedButton from 'components/button/rounded-button';
import { useNotifications } from 'hooks';
import { useQueryClient } from 'react-query';
import useUpdatePoolPhase from 'contracts/poolManager/hooks/useUpdatePoolPhase';
import { useDeployContractContext } from '../context';
import usePhaseMerkleRoot from '../../hooks/usePhaseMerkleRoot';
import { buildPhaseConfig } from '../helpers';

function UpdatePhaseAction({ phase }) {
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const { deal, phaseIdsToUpdate } = useDeployContractContext();

  const queryClient = useQueryClient();

  const { updatePoolPhase, isLoading: isUpdatingPhase } = useUpdatePoolPhase(deal.address, {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', deal.id]);
      queryClient.invalidateQueries(['admin-deal', deal.id]);

      showSuccessNotification('Phase updated successfully');
    },
  });

  const { insertMerkleRoots } = usePhaseMerkleRoot(deal.id, {
    onSuccess: (result) => {
      result.phases.forEach(async (phase) => {
        const phaseConfig = buildPhaseConfig(phase);

        await updatePoolPhase({ phaseId: phase.index, phaseConfig });
      });
    },
    onError: () => {
      showErrorNotification('Error while creating phase');
    },
  });

  return (
    <RoundedButton
      onClick={() => insertMerkleRoots([phase])}
      disabled={isUpdatingPhase || !phaseIdsToUpdate.includes(phase.index)}
      type="secondary"
    >
      Update
    </RoundedButton>
  );
}

export default UpdatePhaseAction;
