import React from 'react';
import NumberInput from 'components/input/number-input';

const BdtLevelCapInput = ({ phaseLevels, setPhaseLevels, disabled }) => {
  const handleChange = (bdtLevel, cap) => {
    setPhaseLevels(phaseLevels.map((level, i) => (bdtLevel === i ? cap : level)));
  };

  return (
    <div className="bdt-level-container ">
      <label>
        Setup levels <span style={{ fontSize: 8 }}>(optional)</span>
      </label>
      <div className="deal-edit-modal__content levels-form-content">
        <NumberInput
          label="Level 0"
          value={phaseLevels[0]}
          onChange={(e) => handleChange(0, e.target.value)}
          decimalNumber="2"
          disabled={disabled}
        />
        <NumberInput
          label="Level 1"
          value={phaseLevels[1]}
          onChange={(e) => handleChange(1, e.target.value)}
          decimalNumber="2"
          disabled={disabled}
        />
        <NumberInput
          label="Level 2"
          value={phaseLevels[2]}
          onChange={(e) => handleChange(2, e.target.value)}
          decimalNumber="2"
          disabled={disabled}
        />
        <NumberInput
          label="Level 3"
          value={phaseLevels[3]}
          onChange={(e) => handleChange(3, e.target.value)}
          decimalNumber="2"
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default BdtLevelCapInput;
