import React, { useState } from 'react';
import './index.scss';
import { useNotifications } from 'hooks';
import CustomSelect from 'components/select/custom-select';
import RoundedButton from 'components/button/rounded-button';
import { PoolStatus } from 'contracts/poolManager/constants';
import { useQueryClient } from 'react-query';
import useUpdatePledgeStatus from 'contracts/pledgeVault/hooks/useUpdatePledgeStatus';
import { ValidPledgeActions, AllPledgeActions } from '../PledgeControl/constants';

const PledgeActionsDropdown = ({ dealId, pool }) => {
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const queryClient = useQueryClient();

  const [selectedStatus, setSelectedStatus] = useState(pool.status);

  const { updatePledgeStatus, isLoading: isUpdating } = useUpdatePledgeStatus(pool.address, {
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-deal', dealId]);

      showSuccessNotification(`Pool status update successfully`);
    },
    onError: () => {
      showErrorNotification(`Error while updating pool status`);
    },
  });

  if (!pool || pool.status === PoolStatus.Canceled) return null;

  console.log('pool:', pool);

  return (
    <div className="pool-actions">
      <CustomSelect
        className="pool-actions"
        value={selectedStatus}
        items={[
          { value: 'Unset' },
          ...AllPledgeActions.map(({ label, status }) => ({
            name: label,
            value: status,
          })),
        ]}
        onSelect={(_, status) => setSelectedStatus(status)}
        absolute
      />
      <RoundedButton
        disabled={
          Number.isNaN(selectedStatus) ||
          isUpdating ||
          !ValidPledgeActions[pool.status]?.includes(selectedStatus)
        }
        type="secondary"
        onClick={() => updatePledgeStatus(selectedStatus)}
      >
        Update
      </RoundedButton>
    </div>
  );
};

export default PledgeActionsDropdown;
