import React, { useState } from 'react';
import SvgIcon from 'components/svgIcon';

import UserRoleTable from './table/UserRoleTable';
import AddUserRoleDialog from './managment/AddUserRoleDialog';

const RoleManagementUsers = ({ users, roles }) => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);

  const handleEdit = (selectedUser) => {
    setIsAddDialogOpen(true);
    setUserToEdit(selectedUser);
  };

  return (
    <>
      <AddUserRoleDialog
        open={isAddDialogOpen}
        onCancel={() => setIsAddDialogOpen(false)}
        roles={roles}
        userToEdit={userToEdit}
        setUserToEdit={setUserToEdit}
      />
      <UserRoleTable users={users} onEdit={handleEdit} />
      <div className="users-table__add" onClick={() => setIsAddDialogOpen(true)}>
        <div>
          <SvgIcon name="plus" width={24} height={24} />
          Add member
        </div>
      </div>
    </>
  );
};

export default RoleManagementUsers;
