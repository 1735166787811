import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UserDealsTable from 'features/deals/UserDealsTable';
import AdminDealsTable from 'features/deals/AdminDealsTable';
import SearchInput from 'components/filters/SearchInput';
import './index.scss';

function Deals() {
  const [dealsLength, setDealsLength] = useState(0);
  const [search, setSearch] = useState('');
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [dealsFilter, setDealsFilter] = useState('all deals');
  const authReducer = useSelector((state) => state.auth);
  const globalReducer = useSelector((state) => state.global);
  const { isAdmin } = authReducer;
  const { userDeals } = globalReducer;

  useEffect(() => {
    setFilteredDeals(userDeals);
  }, [userDeals]);

  useEffect(() => {
    if (!userDeals) {
      return setFilteredDeals([]);
    }
    setFilteredDeals(
      userDeals.filter((deal) => deal.name.toLowerCase().includes(search.toLowerCase()))
    );
  }, [search, userDeals]);

  return (
    <div className="deals-container">
      <div className="deals-header">
        <div className="deals-header-left d-flex">
          <div className="deals-header__title vertical-center">{dealsFilter}</div>
          <div className="deals-header__deals-cnt vertical-center">
            <span>{`${dealsLength} Total`}</span>
          </div>
        </div>
        <div className={`deals-header-right d-flex${showInput ? ' active' : ''}`}>
          <div className={`search-input-container vertical-center${showInput ? ' active' : ''}`}>
            <SearchInput
              searchValue={search}
              updateSearchValue={(searchString) => setSearch(searchString)}
              showInput={showInput}
              setShowInput={setShowInput}
            />
          </div>
        </div>
      </div>
      <div className="deals-content">
        {isAdmin ? (
          <AdminDealsTable
            userDeals={filteredDeals}
            handleDealsLength={setDealsLength}
            dealsFilter={dealsFilter}
            setDealsFilter={setDealsFilter}
          />
        ) : (
          <UserDealsTable
            userDeals={filteredDeals}
            handleDealsLength={setDealsLength}
            dealsFilter="all deals"
            setDealsFilter={setDealsFilter}
            hideTabs
          />
        )}
      </div>
    </div>
  );
}

export default Deals;
