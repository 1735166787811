import { useQuery } from 'react-query';
import { getAllFeatures } from 'services/apiService';

const useFeatures = () => {
  const { data, isFetching } = useQuery(['role-features'], getAllFeatures);

  return {
    features: data,
    isLoading: isFetching,
  };
};

export default useFeatures;
