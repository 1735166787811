import { useSelector } from 'react-redux';
import { ethers } from 'ethers';
import { useMemo } from 'react';
import { PLEDGE_VAULT_ADDRESS } from 'constants/config';
import IPledgeVault from '../abi/IPledgeVault.json';

const usePledgeVault = () => {
  const signer = useSelector((state) => state.global.signer);

  return useMemo(() => {
    if (!PLEDGE_VAULT_ADDRESS) {
      throw new Error('Pledge vault address is not available.');
    }

    if (!signer) {
      throw new Error('Signer is not available. Please connect your wallet.');
    }

    return new ethers.Contract(PLEDGE_VAULT_ADDRESS, IPledgeVault, signer);
  }, [signer]);
};

export default usePledgeVault;
