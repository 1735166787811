const buildInitialState = (totalPledgedAmount) => ({
  totalRelease: {
    amountFormatted: parseFloat(totalPledgedAmount).toFixed(1),
    amount: totalPledgedAmount,
    refund: 0,
    distribute: 0,
    isDirty: true,
  },
  error: '',
  isManualInput: false,
  isDistributeEvenly: false,
});

const calculateNewReleaseAmounts = ({ pledges, totalRelease, value, index }) => {
  if (Number.isNaN(+value)) {
    value = 0;
  }

  const oldAmount = pledges[index].newAmount || 0;

  const diff = +value - +oldAmount;

  const newTotalReleaseAmount = (+totalRelease.distribute || 0) + +diff;

  return { newTotalReleaseAmount: +newTotalReleaseAmount.toFixed(2), newAmount: value };
};

const sumPledges = (pledges) => {
  return pledges.reduce((acc, pledge) => acc + +pledge.newAmount || 0, 0);
};

export { buildInitialState, calculateNewReleaseAmounts, sumPledges };
