import React, { useEffect, useState } from 'react';

const Countdown = ({ timestamp }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(timestamp).getTime() - Date.now();

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return {};
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    if (timerComponents.length === 0) {
      timerComponents.push(
        <div key={timeLeft[interval]}>
          <small>
            {timeLeft.days.toString().length > 1 ? timeLeft.days : `0${timeLeft.days}`}:
            {timeLeft.hours.toString().length > 1 ? timeLeft.hours : `0${timeLeft.hours}`}:
            {timeLeft.minutes.toString().length > 1 ? timeLeft.minutes : `0${timeLeft.minutes}`}:
            {timeLeft.seconds.toString().length > 1 ? timeLeft.seconds : `0${timeLeft.seconds}`}
          </small>
        </div>
      );
    }
  });

  if (Object.keys(timeLeft).length === 0) return null;

  return (
    <div className="countdown">
      <div>{timerComponents.length && timerComponents}</div>
    </div>
  );
};

export default Countdown;
