import { LevelDistribution } from './constants';

function distributeProRataAmongLevels(pledges, amountToDistribute) {
  return pledges.map((pledge) => {
    const levelProportion = LevelDistribution[pledge.level] || 0;

    const proportionalAmount =
      (+pledge.locked / +pledge.totalLockedForLevel) * +amountToDistribute * levelProportion;

    const newAmount = Math.min(pledge.amount, proportionalAmount || 0);

    return {
      ...pledge,
      newAmount: newAmount.toFixed(2),
    };
  });
}

function sumPledges(updatedPledges) {
  return updatedPledges.reduce((acc, pledge) => acc + parseFloat(pledge.newAmount), 0);
}

function distributeLeftAmount(leftAmount, updatedPledges) {
  if (leftAmount <= 0) {
    return;
  }

  updatedPledges.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));

  const delta = -0.0001;
  updatedPledges.forEach((pledge) => {
    if (leftAmount < delta) {
      throw new Error('Difference is less than zero');
    }

    if (leftAmount >= delta && leftAmount <= 0) {
      return;
    }

    const userDifference = pledge.amount - parseFloat(pledge.newAmount);

    if (userDifference > 0) {
      const amountToAdd = Math.min(userDifference, leftAmount);
      pledge.newAmount = (parseFloat(pledge.newAmount) + amountToAdd).toFixed(1);
      leftAmount -= amountToAdd;
    }
  });
}

const distributeAmountAmongPledges = ({ amountToDistribute, pledges }) => {
  const updatedPledges = distributeProRataAmongLevels(pledges, amountToDistribute);

  const totalDistributed = sumPledges(updatedPledges);

  const leftAmount = +amountToDistribute - totalDistributed;

  distributeLeftAmount(leftAmount, updatedPledges);

  return { updatedPledges, amountToDistribute };
};

export default distributeAmountAmongPledges;
