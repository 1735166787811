export const PoolStatus = {
  NotActive: 0,
  Active: 1,
  Paused: 2,
  Closed: 3,
  Canceled: 4,
  Freezed: 10,
};

export default PoolStatus;
