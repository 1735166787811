import buildCall from 'contracts/calls';
import { CALL_TYPE } from 'contracts/calls/constants';
import { buildAllowListDatas } from 'contracts/dealV2Contract';
import { queryCalls } from 'contracts/multicallContract';

async function getPersonalCaps({ deals, rawDeals, walletAddress, provider }) {
  const personalCapCalls = buildPersonalCapCalls({ deals, walletAddress });

  if (personalCapCalls.length === 0) {
    return rawDeals;
  }

  const personalCapResults = await queryCalls(provider, personalCapCalls);

  const personalCapMap = new Map(
    personalCapResults.map(({ call, returnData }) => [call.target, returnData[0]])
  );

  return deals.map((deal, idx) => {
    const rawPersonalCap = personalCapMap.get(deal.address);

    let personalCap;
    if (rawPersonalCap) {
      personalCap = Number(rawPersonalCap);
    }

    return {
      ...deal,
      personalCap,
      ...rawDeals[idx],
    };
  });
}

function buildPersonalCapCalls({ deals, walletAddress }) {
  return deals.flatMap((deal) => {
    const activePhasesIdx = deal.activePhases?.map((phase) => phase.index) || [];

    if (activePhasesIdx.length === 0) {
      return [];
    }

    const allowListDatas = buildAllowListDatas(deal);

    return buildCall(CALL_TYPE.MAX_CONTRIBUTION_AMOUNT)(deal.address, [
      walletAddress,
      activePhasesIdx,
      allowListDatas,
    ]);
  });
}

export { getPersonalCaps };
