import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import SvgIcon from 'components/svgIcon';
import { removeAnimateDeal, updateLoading, setUserDeal } from 'store/actions';
import './index.scss';
import { capitalize, getDealStatusClass, thousandSeparator } from 'utils/helpers';
import moment from 'moment';
import { ethers } from 'ethers';
import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';
import { postPledge } from 'services/apiService';
import { useNotifications } from 'hooks';
import DealInfoDesktop from '../DealInfoUser/DealInfoDesktop';
import UserPledgeControl from './UserPledgeControl';
import useDealInfo from '../DealInfoUser/DealInfoDesktop/DealInfoTab/hooks/useDealInfo';
import PledgeModal from './PledgeModal';

const DraftDealRow = ({ deal: initDeal }) => {
  const globalReducer = useSelector((state) => state.global);

  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeInfoTab, setActiveInfoTab] = useState('info');
  const [pledge, setPledge] = useState({});
  const [pledgeModalOpen, setPledgeModalOpen] = useState(false);
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const { pool, refetch: refetchPoolData } = usePledgePoolQuery(initDeal.address, {
    enabled: false,
  });

  const { deal, isLoading, refetch } = useDealInfo(initDeal, { enabled: false });

  const fetchPoolData = async () => {
    await refetchPoolData();

    const pledgeNumber = ethers.utils.formatUnits(pool.totalPledgedAmount, 6);

    setPledge(pledgeNumber);
  };

  const fetchPledge = async () => {
    dispatch(updateLoading(true));
    try {
      if (!deal.useOldPledge) {
        await fetchPoolData();
      }

      await refetch();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(updateLoading(false));
    }
  };

  const setActivePledgeTab = async () => {
    setActiveInfoTab('pledge');
    await fetchPledge();
  };

  const onPledge = async (data) => {
    try {
      await postPledge(deal.id, data);
      const newDeal = { ...deal, pledge: data.amount, pledgeDate: new Date() };
      dispatch(setUserDeal(newDeal));
      showSuccessNotification('Pledged successfully');
    } catch (err) {
      showErrorNotification(err.response.data.message);
    }
  };

  const { animateDeals } = globalReducer;

  const onManage = (e) => {
    const disabledTags = ['input', 'button'];
    if (disabledTags.includes(e.target.tagName.toLowerCase())) return;
    toggleDrop();
  };

  const toggleDrop = () => {
    setShowDropdown(!showDropdown);
  };

  const getAnimateClass = (val) => {
    if (
      animateDeals &&
      animateDeals.fields &&
      deal.address === animateDeals.address &&
      (animateDeals?.fields.includes(val) || animateDeals?.fields.includes('allFields'))
    ) {
      return 'animate';
    }
    return val;
  };

  useEffect(() => {
    if (animateDeals && animateDeals.fields && deal.address === animateDeals.address) {
      setTimeout(() => {
        dispatch(removeAnimateDeal());
      }, 3000);
    }
  }, [animateDeals, deal, dispatch]);

  if (isLoading) return null;

  return (
    <>
      <PledgeModal
        isOpen={pledgeModalOpen}
        onOk={onPledge}
        onCancel={() => setPledgeModalOpen(false)}
        fetchPledge={fetchPledge}
        defaultValue={deal.pledge}
      />
      <div className="deal-holder deal-holder-draft d-flex full-width">
        <div className="deal-row-top" onClick={onManage}>
          <div
            className={`deal__field deal__field-avatar vertical-center ${getAnimateClass(
              'imageUrl'
            )}`}
          >
            <RoundedAvatar src={deal.imageUrl} />
          </div>
          <div
            className={`deal__field deal__field-name vertical-center ${getAnimateClass('name')}`}
          >
            <div>
              <span>{deal.name}</span>
            </div>
          </div>
          <div className="deal__field deal__field-status deal__field-status vertical-center">
            <span className="deal__field-status__name">
              {moment(deal.dateCreated || deal.createdAt).format('D-MMM-YYYY')}
            </span>
          </div>
          <div
            className={`deal__field deal__field-status deal__field-status--${getDealStatusClass(
              deal.status
            )} vertical-center ${getAnimateClass('status')}`}
          >
            <span className="deal__field-status__icon">
              <SvgIcon name="dot" />
            </span>
            <span className="deal__field-status__name">
              {deal.statusId === 6 || deal.statusId === 0 ? 'Draft' : capitalize(deal.status)}
            </span>
          </div>
          <div className="deal__field deal__field-pledge-amount vertical-center">
            <span className="deal__field-status__name">
              $ {thousandSeparator(deal.pledgeAmount || 0)}
            </span>
          </div>
          <div className="deal__field deal__field-pledge-amount vertical-center">
            <span className="deal__field-status__name">
              $ {thousandSeparator(deal.pledgeOriginalAmount || 0)}
            </span>
          </div>
          <UserPledgeControl deal={deal} onLoad={fetchPledge} />
        </div>

        <DealInfoDesktop
          deal={deal}
          showDropdown={showDropdown}
          activeInfoTab={activeInfoTab}
          setActiveInfoTab={setActiveInfoTab}
          setActivePledgeTab={setActivePledgeTab}
          pledge={pledge}
        />
      </div>
    </>
  );
};

DraftDealRow.propTypes = {
  deal: PropTypes.shape(),
};

DraftDealRow.defaultProps = {
  deal: {},
};

export default React.memo(DraftDealRow);
