import React, { useState } from 'react';
import InfoTooltip from 'components/tooltip/custom/InfoTooltip';
import PledgeStatus from 'contracts/pledgeVault/constants';
import RoundedButton from 'components/button/rounded-button';
import withNewPledge from '../../hoc/withNewPledge';
import UpdatePledgesPopoup from '../UpdatePledgesPopoup';

function UpdatePledgesButton({ deal, pool, pledges, onRefetch }) {
  const [openUpdatePledges, setOpenUpdatePledges] = useState(false);

  return (
    <div className="holder-buttons">
      <RoundedButton
        disabled={pool?.status !== PledgeStatus.Freezed}
        type="secondary"
        onClick={() => setOpenUpdatePledges(true)}
      >
        Update pledges
      </RoundedButton>
      <InfoTooltip title="Update pledges" />
      <UpdatePledgesPopoup
        deal={deal}
        pledges={pledges}
        isOpen={openUpdatePledges}
        onClose={() => setOpenUpdatePledges(false)}
        onRefetch={onRefetch}
      />
    </div>
  );
}

export default withNewPledge(UpdatePledgesButton);
