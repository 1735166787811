import { ethers } from 'ethers';
import { decodeDealInfo } from 'models/common/decoders';

function parseEssentialData(multicallResults, callIndex) {
  const rawChainInfo = multicallResults[callIndex.dealInfoIndex].returnData;
  const rawContributionAmount = multicallResults[callIndex.contributionIndex].returnData[0];

  return {
    dealChainData: decodeDealInfo(rawChainInfo),
    contributedAmount:
      rawContributionAmount && ethers.utils.formatUnits(rawContributionAmount, 'mwei'),
  };
}

function filterAccessibleDeals(liveDealResults, accountInfo) {
  return liveDealResults.filter(
    ({ minViewLevel, userWhitelisted, contributedAmount, minAccessLevel }) => {
      // TODO => minViewLevel is not used in v3?

      const isWhitelistedForMinViewLevel = minViewLevel === 4 && userWhitelisted;
      const isWhitelistedForMinAccessLevel = minAccessLevel === 4 && userWhitelisted;

      const hasSufficientViewLevel = (minViewLevel || 0) <= accountInfo.userViewLevel;
      const hasContributed = !!+contributedAmount;

      return (
        hasSufficientViewLevel ||
        isWhitelistedForMinViewLevel ||
        isWhitelistedForMinAccessLevel ||
        hasContributed
      );
    }
  );
}

export { parseEssentialData, filterAccessibleDeals };
