import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DealSearchInput from 'features/deals/DealSearchInput';
import AddDraftModal from 'features/drafts/AddDraftModal';
import { useHistory } from 'react-router-dom';
import { addUserDeal } from 'store/actions';
import IconButton from 'components/button/icon-button';
import { useNotifications } from 'hooks';
import { createDeal } from 'services/apiService';
import AdminDealsTable from 'features/deals/AdminDealsTable';

const Drafts = () => {
  const dispatch = useDispatch();
  const [dealsLength, setDealsLength] = useState(0);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [addDraftModalOpen, setAddDraftModalOpen] = useState(false);
  const authReducer = useSelector((state) => state.auth);
  const globalReducer = useSelector((state) => state.global);
  const { isAdmin } = authReducer;
  const { userDeals } = globalReducer;
  const history = useHistory();
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  useEffect(() => {
    if (!isAdmin) return history.push('/');
  }, [isAdmin, history]);

  const toggleAddDraftModal = () => {
    setAddDraftModalOpen(!addDraftModalOpen);
  };

  useEffect(() => {
    setFilteredDeals(userDeals);
  }, [userDeals]);

  const onAddDeal = async (data) => {
    try {
      const response = await createDeal(data);
      const newDeal = { ...response.data };
      newDeal.status = 'None';
      dispatch(addUserDeal(newDeal));
      toggleAddDraftModal();
      showSuccessNotification('Draft created sucessfully');
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong');
    }
  };

  return (
    <>
      {addDraftModalOpen && <AddDraftModal onOk={onAddDeal} onCancel={toggleAddDraftModal} />}
      <div className="deals-container">
        <div className="deals-header">
          <div className="deals-header-left d-flex">
            <div className="deals-header__title vertical-center">Drafts</div>
            <div className="deals-header__deals-cnt vertical-center">
              <span>{`${dealsLength} Total`}</span>
            </div>
          </div>
          <div className={`deals-header-right d-flex${showInput ? ' active' : ''}`}>
            {isAdmin && <IconButton icon="plus" onClick={toggleAddDraftModal} />}
            <div className={`search-input-container vertical-center${showInput ? ' active' : ''}`}>
              <DealSearchInput
                deals={userDeals}
                onChange={setFilteredDeals}
                showInput={showInput}
                setShowInput={setShowInput}
              />
            </div>
          </div>
        </div>
        <div className="deals-content">
          <AdminDealsTable
            userDeals={filteredDeals}
            handleDealsLength={setDealsLength}
            dealsFilter="drafts"
            hideTabs
          />
        </div>
      </div>
    </>
  );
};

export default Drafts;
