import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { updateLoading, addNotification, setActiveHashes } from 'store/actions';
import { DEFAULT_CHAIN_NAME } from 'constants/config';
import notificationTypes from 'constants/notificationTypes';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';

const useContractMethod = (
  methodFn,
  { onSuccess, onError, errorMessage, listenerOptions: { eventName, topics = [] } }
) => {
  const dispatch = useDispatch();

  const { mutateAsync: executeContractMethod, ...rest } = useMutation(
    async (...args) => {
      const tx = await methodFn(...args);

      const receipt = await tx.wait();

      if (!eventName) {
        return { receipt };
      }

      const event = receipt.events.find((e) => e.event === eventName);

      if (!event) {
        throw new Error(errorMessage);
      }

      const results = topics.reduce((acc, topic) => {
        acc[topic] = event.args[topic];

        return acc;
      }, {});

      return { receipt, ...results };
    },
    {
      onMutate: () => {
        dispatch(updateLoading(true));
      },
      onSuccess: ({ receipt, ...eventArgs }) => {
        dispatch(updateLoading(false));

        dispatch(
          setActiveHashes([
            {
              chain: DEFAULT_CHAIN_NAME,
              hash: receipt.transactionHash,
              callback: onSuccess?.(eventArgs),
              pending: false,
            },
          ])
        );

        dispatch(
          addNotification({
            name: receipt.transactionHash,
            chain: DEFAULT_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
      },
      onError: () => {
        dispatch(updateLoading(false));
        dispatch(
          addNotification({
            name: 'Transaction Error',
            status: 'error',
            statusText: errorMessage,
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );

        if (onError) onError();
      },
    }
  );

  const checkedExecuteMethod = useWithCorrectNetwork(executeContractMethod);

  return {
    executeMethod: checkedExecuteMethod,
    ...rest,
  };
};

export default useContractMethod;
