import { useDispatch } from 'react-redux';
import {
  setUserDeals,
  setAlertNotification,
  updateFetchingDeals,
  setOldDeals,
} from 'store/actions';
import { ADMIN_ADDRESS } from 'constants/index';
import { useCallback, useState } from 'react';
import useAdminDealsQuery from './useAdminDealsQuery';
import useUserDealsQuery from './useUserDealsQuery';
import useOldDealsQuery from './useOldDealsQuery';

const useLoadDeals = () => {
  const dispatch = useDispatch();

  const [authState, setAuthState] = useState();
  const [fetchState, updateFetchState] = useState({
    fetchUser: false,
    fetchAdmin: false,
  });

  const onSuccess = (deals) => {
    if (
      authState.idVerified === 'verification_approved' ||
      authState.idVerified === 'approved' ||
      authState.isAdmin
    ) {
      dispatch(setUserDeals(deals));
      return;
    }

    const filteredDeals = deals.filter(
      ({ contributedAmount }) => contributedAmount && +contributedAmount
    );

    dispatch(setUserDeals(filteredDeals));
    dispatch(updateFetchingDeals(false));
  };

  const onError = (error) => {
    console.error(error);
    dispatch(
      setAlertNotification({
        status: 'error',
        title: 'Error',
        description: 'Could not load platform deals.',
      })
    );
  };

  useAdminDealsQuery(authState, {
    onSuccess,
    onError,
    enabled: fetchState.fetchAdmin,
  });

  useUserDealsQuery(authState, {
    onSuccess,
    onError,
    enabled: fetchState.fetchUser,
  });

  useOldDealsQuery(authState, {
    onSuccess: (deals) => {
      dispatch(setOldDeals(deals || []));
      dispatch(updateFetchingDeals(false));
    },
    onError,
    enabled:
      !!authState && authState?.accountInfo?.address?.toLowerCase() !== ADMIN_ADDRESS.toLowerCase(),
  });

  return {
    fetchUserDeals: useCallback(
      (newAuthState) => {
        dispatch(updateFetchingDeals(true));
        setAuthState(newAuthState);

        if (newAuthState.isAdmin) {
          updateFetchState((state) => ({ ...state, fetchAdmin: true }));
        } else {
          updateFetchState((state) => ({ ...state, fetchUser: true }));
        }
      },
      [dispatch]
    ),
  };
};

export default useLoadDeals;
