import PledgeStatus from 'contracts/pledgeVault/constants';

export const PledgeLabel = {
  [PledgeStatus.NotActive]: 'Not Active',
  [PledgeStatus.OpenUnlocked]: 'Open Unlocked',
  [PledgeStatus.OpenLocked]: 'Open Locked',
  [PledgeStatus.Freezed]: 'Freezed',
  [PledgeStatus.Canceled]: 'Canceled',
  [PledgeStatus.Finalized]: 'Finalized',
};

export const AllPledgeActions = [
  { label: PledgeLabel[PledgeStatus.OpenUnlocked], status: PledgeStatus.OpenUnlocked },
  { label: PledgeLabel[PledgeStatus.OpenLocked], status: PledgeStatus.OpenLocked },
  { label: PledgeLabel[PledgeStatus.Freezed], status: PledgeStatus.Freezed },
  { label: PledgeLabel[PledgeStatus.Canceled], status: PledgeStatus.Canceled },
  { label: PledgeLabel[PledgeStatus.Finalized], status: PledgeStatus.Finalized },
];

export const ValidPledgeActions = {
  [PledgeStatus.OpenUnlocked]: [
    PledgeStatus.OpenLocked,
    PledgeStatus.Freezed,
    PledgeStatus.Canceled,
  ],
  [PledgeStatus.OpenLocked]: [
    PledgeStatus.OpenUnlocked,
    PledgeStatus.Freezed,
    PledgeStatus.Canceled,
  ],
  [PledgeStatus.Freezed]: [
    PledgeStatus.OpenUnlocked,
    PledgeStatus.OpenLocked,
    PledgeStatus.Canceled,
  ],
  [PledgeStatus.Finalized]: [],
};

export const PledgeStatusDescription = [
  'Not Active - Default',
  'Open Unlocked - Pledge allowed, release allowed for users. Transition allowed.',
  'Open Locked - Pledge allowed, release NOT allowed. Transition allowed.',
  'Freezed - Pledge NOT allowed, release NOT allowed. Transition allowed.',
  'Canceled - Pledge NOT allowed, release allowed for admin. Transition NOT allowed.',
  'Finalized - Pledge NOT allowed, release NOT allowed. Transition NOT allowed.',
];
