import PoolStatus from 'contracts/poolManager/constants';

const ValidStatusTransitions = {
  [PoolStatus.Active]: [PoolStatus.Paused, PoolStatus.Canceled, PoolStatus.Closed],
  [PoolStatus.Paused]: [PoolStatus.Active, PoolStatus.Canceled, PoolStatus.Closed],
  [PoolStatus.Closed]: [PoolStatus.Canceled],
};

export const isActionEnabled = (poolStatus, status) => {
  const validTransitions = ValidStatusTransitions[poolStatus];

  return validTransitions && validTransitions.includes(status);
};

export const getMinAccessLevel = (levelsActive) => {
  for (let i = 0; i < levelsActive.length; i++) {
    if (levelsActive[i]) {
      return i;
    }
  }
  return -1; // Return -1 if no active level is found
};
