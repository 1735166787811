import React, { useState } from 'react';
import { Divider } from '@material-ui/core';
import useRoles from 'features/roleManagement/hooks/useRoles';
import useUsersWithRoles from 'features/roleManagement/hooks/useUsersWithRoles';
import useFeatures from 'features/roleManagement/hooks/useFeatures';
import RoleManagementUsers from 'features/roleManagement/users/RoleManagementUsers';
import RoleManagementRoles from 'features/roleManagement/roles/RolesManagment';
import RoleManagementContract from 'features/roleManagement/contracts/RoleManagementContract';
import Tabs from 'components/tabs';
import './index.scss';

const RoleManagement = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { roles, isLoading: isRolesLoading } = useRoles();
  const { users, isLoading: isUsersLoading } = useUsersWithRoles();
  const { features, isLoading: isFeaturesLoading } = useFeatures();

  if (isRolesLoading || isUsersLoading || isFeaturesLoading) return <div>Loading...</div>;

  if (!roles || !users || !features) return <div>No data available</div>;

  return (
    <div className="user-management">
      <div className="user-management__header">
        <h2>User Management</h2>
        <span>
          {users ? users.length : 0} {users && users.length === 1 ? 'User' : 'Users'}
        </span>
      </div>
      <Divider />
      <div className="user-management__content">
        <Tabs
          tabs={[
            {
              id: 1,
              label: 'User',
              content: <RoleManagementUsers users={users} roles={roles.all} />,
            },
            {
              id: 2,
              label: 'Roles',
              content: <RoleManagementRoles roles={roles.customRoles} features={features} />,
            },
            {
              id: 3,
              label: 'Contract',
              content: <RoleManagementContract contractRoles={roles.contractRoles} />,
            },
          ]}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
      </div>
    </div>
  );
};

export default RoleManagement;
