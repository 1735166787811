import React, { useMemo } from 'react';
import { Divider } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import CustomInput from 'components/input/custom-input';
import useRoleUpsert from 'features/roleManagement/hooks/useRoleUpsert';
import { useNotifications } from 'hooks';
import RoleFeatures from './RoleFeatures';
import RoleDealManagement from './RoleDealManagement';

const RoleDetails = ({
  role,
  setRole,
  features,
  isDealManagementSelected,
  setIsDealManagementSelected,
  onClose,
}) => {
  const [, showErrorNotification] = useNotifications();

  const { upsertRole } = useRoleUpsert(role, {
    onSuccess: () => {
      onClose();
    },
    onError: () => {
      showErrorNotification('Error with processing role');
      onClose();
    },
  });

  const handleChange = (e) => {
    const { value } = e.target;

    setRole({ ...role, name: value });
  };

  const isSaveDisabled = useMemo(() => {
    const initialCondition = !role.name || !role.featuresIds?.length;

    if (isDealManagementSelected) {
      return initialCondition || !role.maxCloseAmount;
    }

    return initialCondition;
  }, [isDealManagementSelected, role]);

  return (
    <div className="role-list__details">
      <div className="details-header">
        <h3>Role details</h3>
        <RoundedButton type="primary" disabled={isSaveDisabled} onClick={upsertRole}>
          Save
        </RoundedButton>
      </div>
      <Divider />
      <div className="details-body">
        <div className="role-info">
          <CustomInput
            label="Role Name"
            name="name"
            value={role.name}
            onChange={handleChange}
            disabled={!!role.id}
          />
        </div>
        <RoleDealManagement
          role={role}
          setRole={setRole}
          isDealManagementSelected={isDealManagementSelected}
          setIsDealManagementSelected={setIsDealManagementSelected}
        />
        <RoleFeatures role={role} setRole={setRole} features={features} />
      </div>
    </div>
  );
};

export default RoleDetails;
