import { useQuery } from 'react-query';
import { useNotifications } from 'hooks';
import { PLEDGE_VAULT_ADDRESS } from 'constants/config';
import useReadonlyPledgeVault from './useReadonlyPledgeVault';

const usePledgePoolQuery = (poolAddress, options = {}) => {
  const [, showErrorNotification] = useNotifications();

  const pledgeContract = useReadonlyPledgeVault(PLEDGE_VAULT_ADDRESS);

  const { data: pool, error, isFetching, refetch } = useQuery(
    ['pool', poolAddress],
    () => pledgeContract.pools(poolAddress),
    {
      ...options,
      onError: () => {
        showErrorNotification('Error fetching pledge amount');
      },
      enabled: !!poolAddress,
    }
  );

  return {
    pool,
    error,
    refetch,
    isLoading: isFetching,
  };
};

export default usePledgePoolQuery;
