import { decodeDealInfo } from 'models/common/decoders';
import { queryCalls } from 'contracts/multicallContract';
import buildCall from 'contracts/calls';
import { CALL_TYPE } from 'contracts/calls/constants';
import { getProviderByChainId } from '../../contracts/providers';
import { groupDealsByStatus, groupByChain, groupDealsByVersion } from '../common/helpers';
import { getPersonalCaps } from './personalCap';
import { enrichWithMetadata } from './metadata';

// TODO: step2 : get deals of the connected account
export async function getAdminDealModels(newAuthState, apiDeals) {
  const { accountInfo } = newAuthState;

  const walletAddress = accountInfo.address;

  const { newDeals, oldDeals } = groupDealsByVersion(apiDeals);

  let { liveDeals, otherDeals } = groupDealsByStatus(newDeals);

  liveDeals = await processLiveDeals(liveDeals, walletAddress);

  otherDeals = otherDeals.map((otherDeal) => ({
    ...otherDeal,
    status: otherDeal.status.toLowerCase(),
    id: otherDeal.id.toString(),
  }));

  return [...liveDeals, ...otherDeals, ...oldDeals];
}

async function processLiveDeals(live, walletAddress) {
  const dealsByChain = groupByChain(live);

  // eslint-disable-next-line no-use-before-define
  const rawDeals = await processDealsPerChain(dealsByChain, walletAddress);

  return enrichWithMetadata(rawDeals, walletAddress);
}

async function processDealsPerChain(dealsByChain, walletAddress) {
  const results = [];

  // eslint-disable-next-line no-restricted-syntax
  for await (const [chainId, dealsApi] of Object.entries(dealsByChain)) {
    const provider = getProviderByChainId(chainId);

    const multicallCalls = dealsApi.flatMap((deal) => [
      buildCall(CALL_TYPE.DEAL_INFO)(deal.address),
    ]);

    const multicallResults = await queryCalls(provider, multicallCalls);

    let rawDeals = processDeals(dealsApi, multicallResults);

    if (walletAddress) {
      rawDeals = await getPersonalCaps({ deals: dealsApi, rawDeals, walletAddress, provider });
    }

    results.push(...rawDeals);
  }

  return results;
}

function processDeals(deals, multicallResults) {
  return deals.map((deal, idx) => {
    const rawDealInfo = multicallResults[idx].returnData;
    const dealChainData = decodeDealInfo(rawDealInfo);

    return {
      deal,
      dealChainData,
    };
  });
}
