import React, { createContext, useState, useEffect, useContext } from 'react';
import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';
import usePoolQuery from 'contracts/pledgeVault/hooks/usePoolQuery';
import { DealVersion } from 'models/constants';
import { InitialPhaseState } from '../constants';
import { isValidPledgeStatus, mapPhases } from '../helpers';

const DeployContractContext = createContext();

export const useDeployContractContext = () => useContext(DeployContractContext);

export const DeployContractProvider = ({ deal, children }) => {
  const [whitelistPickerData, setWhitelistPickerData] = useState(null);
  const [phases, setPhases] = useState([InitialPhaseState]);
  const [phaseIdsToUpdate, setPhaseIdsToUpdate] = useState([]);
  const [poolConfig, setPoolConfig] = useState({
    totalContribution: '',
    minContribution: '',
    maxContribution: '',
    dealSize: '',
  });

  const { pool, isLoading: isLoadingPool } = usePoolQuery(deal.address, {
    enabled: deal.version === DealVersion.V3,
  });

  const { pool: pledgePool, isLoading: isLoadingPledgePool } = usePledgePoolQuery(deal.address);

  useEffect(() => {
    if (!pool) {
      return;
    }

    setPoolConfig({
      dealSize: pool.dealSize,
      minContribution: pool.minContribution,
      totalContribution: pool.totalContribution,
      maxContribution: +pool.maxContribution || '',
    });
  }, [pool]);

  useEffect(() => {
    if (!deal.phases || !deal.phases.length) return;

    setPhaseIdsToUpdate([]);
    setPhases(() => mapPhases(deal.phases));
  }, [deal.phases]);

  const toggleExpand = (index) => {
    setPhases((prev) =>
      prev.map((phase, i) => (index === i ? { ...phase, expanded: !phase.expanded } : phase))
    );
  };

  const handlePhaseChange = (name, value, index) => {
    let { expanded } = phases[index];

    // 4 -> whitelist
    if (['WalletCap', '4'].includes(value)) {
      expanded = true;
    }

    setPhaseIdsToUpdate((prev) => {
      if (deal.phases.find((p) => p.index === phases[index].index)) {
        return [...prev, phases[index].index];
      }

      return prev;
    });

    setPhases((prev) =>
      prev.map((phase, i) =>
        index === i ? { ...phase, [name]: value, expanded, index: i + 1 } : phase
      )
    );
  };

  return (
    <DeployContractContext.Provider
      value={{
        deal,
        pool,
        phases,
        setPhases,
        pledgePool,
        poolConfig,
        setPoolConfig,
        toggleExpand,
        phaseIdsToUpdate,
        handlePhaseChange,
        whitelistPickerData,
        setWhitelistPickerData,
        isLoadingPool: isLoadingPool || isLoadingPledgePool,
        isValidPledgeStatus: isValidPledgeStatus(deal, pledgePool),
      }}
    >
      {children}
    </DeployContractContext.Provider>
  );
};
