import Checkbox from 'components/checkbox';
import React from 'react';

const RoleFeatures = ({ role, setRole, features }) => {
  const featuresIds = role.featuresIds || [];

  const handleCheckboxChange = (e, featureId) => {
    const updatedFeaturesIds = e.target.checked
      ? [...featuresIds, featureId]
      : featuresIds.filter((id) => id !== featureId);

    setRole({ ...role, featuresIds: updatedFeaturesIds });
  };

  const handleAllListCheckboxChange = (e, featuresListIds) => {
    const updatedFeaturesIds = e.target.checked
      ? [...new Set([...featuresIds, ...featuresListIds])]
      : featuresIds.filter((id) => !featuresListIds.includes(id));

    setRole({ ...role, featuresIds: updatedFeaturesIds });
  };

  return (
    <div className="role-info-container">
      <span className="features-label">Features</span>
      {features.map(({ featuresGroup, featureList, ids }) => {
        const isEntireFeatureListChecked = ids.every((id) => role?.featuresIds?.includes(id));

        return (
          <div key={featuresGroup}>
            <div className="checkbox-option-container">
              <Checkbox
                checked={isEntireFeatureListChecked}
                onChange={(e) => handleAllListCheckboxChange(e, ids)}
              />
              <span>{featuresGroup}</span>
            </div>
            <div className="features-list-container">
              {featureList[0].feature &&
                featureList.map((featureItem) => (
                  <div key={featureItem.id} className="feature-list-item-container">
                    <Checkbox
                      checked={role.featuresIds?.includes(featureItem.id)}
                      onChange={(e) => handleCheckboxChange(e, featureItem.id)}
                    />
                    <span>{featureItem.feature}</span>
                  </div>
                ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RoleFeatures;
