import React, { useState } from 'react';
import PledgeStatus from 'contracts/pledgeVault/constants';
import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';
import PoolCreateButton from '../../../PoolCreateButton';
import PledgeActionsDropdown from '../../../PledgeActionsDropdown';
import { PledgeLabel } from '../../constants';

export const PledgeLabelColor = {
  [PledgeStatus.NotActive]: 'white',
  [PledgeStatus.OpenUnlocked]: 'green',
  [PledgeStatus.OpenLocked]: 'green',
  [PledgeStatus.Freezed]: 'white',
  [PledgeStatus.Canceled]: 'red',
};

const NewPledgeControl = ({ deal }) => {
  // TODO -> workaround solution before introducing react query
  const [poolAddress, setPoolAddress] = useState(deal.address);

  const { pool, isLoading: isPoolLoading } = usePledgePoolQuery(poolAddress);

  return (
    <>
      {isPoolLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="vertical-flex pledge-control-container">
          <h2 className={`${PledgeLabelColor[pool?.status || 0]} mb-10`}>
            {PledgeLabel[pool?.status || 0].toUpperCase()}
          </h2>
          <div className="horizontal-flex justify-space-between">
            {!poolAddress ? (
              <PoolCreateButton dealId={deal.id} onCreate={(address) => setPoolAddress(address)} />
            ) : (
              <PledgeActionsDropdown dealId={deal.id} pool={{ ...pool, address: poolAddress }} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default NewPledgeControl;
