import { ethers } from 'ethers';
import PoolArtifact from '../poolManager/abi/IPool.json';
import ClaimerArtifact from '../abis/Claimer.json';
import ERC20Artifact from '../abis/ERC20.json';

function buildDealInfoCall(targetAddress) {
  // deal_size;
  // min_contribution;
  // max_contribution;
  // totalContribution;
  // status;
  return {
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(PoolArtifact).encodeFunctionData('getPoolInfo'),
    returnTypes: [
      'tuple(uint256 dealSize, uint256 minimumUserContribution, uint256 maximumUserContribution, uint256 totalContribution, uint8 status)',
    ],
  };
}

function buildClaimAmountCall(targetAddress, chainId, callParams) {
  return {
    chainId,
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(PoolArtifact).encodeFunctionData(
      'getWithdrawableAmount',
      callParams
    ),
    fallbackValue: ethers.utils.defaultAbiCoder.encode(['uint256'], ['0']),
    returnTypes: ['uint256'],
  };
}

function buildOldClaimAmountCall(targetAddress, chainId, callParams) {
  return {
    chainId,
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(ClaimerArtifact.abi).encodeFunctionData(
      'getAddressAvailableClaimAmount',
      callParams
    ),
    fallbackValue: ethers.utils.defaultAbiCoder.encode(['uint256'], ['0']),
    returnTypes: ['uint256'],
  };
}

function buildAddressContributionAmountCall(targetAddress, callParams) {
  return {
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(PoolArtifact).encodeFunctionData(
      'userContributions',
      callParams
    ),
    fallbackValue: ethers.utils.defaultAbiCoder.encode(['uint256'], ['0']),
    returnTypes: ['uint256'],
  };
}

function buildMaxContributionAmountCall(targetAddress, callParams) {
  return {
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(PoolArtifact).encodeFunctionData(
      'getRemainingUserAllocationForPhases',
      callParams
    ),
    fallbackValue: ethers.utils.defaultAbiCoder.encode(['uint256'], ['0']),
    returnTypes: ['uint256'],
  };
}

function buildTokenInfoCall(targetAddress, chainId) {
  const nameCall = {
    chainId,
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(ERC20Artifact.abi).encodeFunctionData('name'),
    fallbackValue: 'Unknown',
    returnTypes: ['string'],
  };

  const symbolCall = {
    chainId,
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(ERC20Artifact.abi).encodeFunctionData('symbol'),
    fallbackValue: 'Unknown',
    returnTypes: ['string'],
  };

  const decimalsCall = {
    chainId,
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(ERC20Artifact.abi).encodeFunctionData('decimals'),
    fallbackValue: ethers.utils.defaultAbiCoder.encode(['uint8'], ['18']),
    returnTypes: ['uint8'],
  };

  return [nameCall, symbolCall, decimalsCall];
}

export {
  buildDealInfoCall,
  buildClaimAmountCall,
  buildOldClaimAmountCall,
  buildAddressContributionAmountCall,
  buildMaxContributionAmountCall,
  buildTokenInfoCall,
};
