import { useMutation } from 'react-query';
import { addRole as apiCreateRole } from 'services/apiService';

const useApiCreateRole = (role, { onSuccess, onError }) => {
  const { mutateAsync: createRole, isLoading } = useMutation(() => apiCreateRole(role), {
    onSuccess,
    onError,
  });

  return {
    createRole,
    isLoading,
  };
};

export default useApiCreateRole;
