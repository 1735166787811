import { updateDeal } from 'services/apiService';
import { useQueryClient, useMutation } from 'react-query';
import { useNotifications } from 'hooks';

function useOldClosePledge(dealId) {
  const queryClient = useQueryClient();

  const [showSuccessNotification, showErrorNotification] = useNotifications();

  return useMutation(() => updateDeal(dealId, { statusId: 8 }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-deal', dealId]);

      showSuccessNotification('Pledge closed');
    },
    onError: () => {
      showErrorNotification('Failed to close pledge');
    },
  });
}

export default useOldClosePledge;
