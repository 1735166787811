import { ethers } from 'ethers';
import { useQuery } from 'react-query';
import { useNotifications } from 'hooks';
import { getDealStatusName } from 'models/dealStatusModel';
import useReadonlyPool from './useReadonlyPool';

const usePoolQuery = (poolAddress, options = {}) => {
  const [, showErrorNotification] = useNotifications();

  const poolContract = useReadonlyPool(poolAddress);

  const { data: pool, error, isFetching, refetch } = useQuery(
    ['pool-info', poolAddress],
    async () => {
      const poolInfo = await poolContract.getPoolInfo();

      if (!poolInfo) {
        return {
          statusId: 0,
          status: 'Pending',
          dealSize: 0,
          minContribution: 0,
          maxContribution: 0,
          totalContribution: 0,
        };
      }

      return {
        statusId: poolInfo.status,
        status: getDealStatusName(poolInfo.status),
        dealSize: ethers.utils.formatUnits(poolInfo.dealSize, 6),
        totalContribution: ethers.utils.formatUnits(poolInfo.totalContribution, 6),
        minContribution: ethers.utils.formatUnits(poolInfo.minimumUserContribution, 6),
        maxContribution: ethers.utils.formatUnits(poolInfo.maximumUserContribution, 6),
      };
    },
    {
      ...options,
      onError: () => {
        showErrorNotification('Error fetching pool');
      },
      enabled: !!options.enabled && !!poolAddress,
    }
  );

  return {
    pool,
    error,
    refetch,
    isLoading: isFetching,
  };
};

export default usePoolQuery;
