const StateField = {
  Amount: 'amount',
  Error: 'error',
  IsDirty: 'isDirty',
  IsManualInput: 'isManualInput',
  IsDistributeEvenly: 'isDistributeEvenly',
};

const LevelDistribution = {
  1: 0.05,
  2: 0.3,
  3: 0.65,
};

export { StateField, LevelDistribution };
